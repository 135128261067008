<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">

          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow ">

                <span style="color: white;"> Listado de Funcionarios</span>
              </v-col>

              <v-btn to="/create-official" color=" mr-2">
                <v-icon left>
                  mdi-account-multiple-plus
                </v-icon>
                Registrar Funcionario
              </v-btn>

            </v-row>
          </v-toolbar>
          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="5" class="ml-10">
              <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
            </v-col>
          </v-row>
          <v-card-text>
            <v-data-table :headers="headers" :items="official" :search="search"
              :loading="loading"
              loading-text="Cargando... Por favor espere"
            :footer-props="{
  itemsPerPageText: 'Filas por página',
  itemsPerPageAllText: 'todos',
}" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
 
              <template slot="item.region_name" slot-scope="{ item }">
                <span>{{ getRegionById(item.region_id) }}</span>
              </template>

              <template v-slot:[`item.commune_name`]="{ item }">
                <span>{{ getCommuneById(item.commune_id) }}</span>
              </template>

              <template v-slot:[`item.function_name`]="{ item }">
                <span>{{ getFunctionById(item.function_official_id) }}</span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip top>
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon :to="'/update-official/' + item.id">
                      <v-icon v-bind="attrs" color="primary" v-on="on">
                        mdi-pencil-box-multiple
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Editar</span>
                </v-tooltip>

                <v-dialog transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                      <v-card-text>
                        <div class="text-h7 pa-6">
                          ¿Estás seguro que deseas eliminar el funcionario?
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                        <v-btn class="primary" text @click="
  dialog.value = false;
deleteItem(item);
                      ">Aceptar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      loading:true,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      headers: [      
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Apellido Paterno", value: "last_name" },
        { text: "Apellido Materno", value: "mother_last_name" },
        { text: "Dirección", value: "direction" },
        { text: "Región", value: "region_name" },
        { text: "Comuna", value: "commune_name" },
        { text: "Función", value: "function_name" },
        { text: "Acciones", value: "actions" },
      ],

      official: [],
      functions: [],
      regions: [],
      communes: [],
    }
  },

  mounted() {
    this.action = "Eliminar"
    this.getFunctions()
    this.getRegions()
    this.getCommunes()
    this.getOfficials()

  },

  methods: {
    getRegionById(region_id) {
      const region = this.regions.find((r) => r.id === region_id);
      return region ? region.name : '';
    },

    getCourseName(courseId) {
      const course = this.courses.find(course => course.id === courseId);
      return course ? course.name : '';
    },

    getCommuneById(commune_id) {
      let commune = this.communes.find((c) => c.id === commune_id);
      return commune.name;
    },

    getFunctionById(function_official_id) {
      let function_official = this.functions.find((f) => f.id === function_official_id);
      return function_official.name;
    },


    getFunctions() {
      axios
        .get("/functionOfficial-index")
        .then((response) => {
          this.functions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las funciones"
          );
        });
    },

    getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    getCommunes() {
      axios
        .get("/commune-index")
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    deleteItem(item) {      
      let request = {
        id: item.id,
      };
      axios
        .post("/official-destroy", request)
        .then(() => {         
          this.displayNotification("success", "Éxito", "Funcionario Eliminado");
          this.getOfficials()
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el Funcionario"
          );
        });
    },
    getOfficials() {
      axios
        .get("/official-index")
        .then((response) => {
          console.log("Funcionarios");
          console.log(response.data);
          this.official = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estudiantes"
          );
        }).finally(()=>
        {
         
          this.loading=false
        });
    },


  },
};
</script>