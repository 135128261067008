<template>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-card outlined class="elevation-12">
            <v-card-title>
             
            </v-card-title>
            <v-card-text class="text-center">
              <span class="text-h5">Oops! <br> La página que estás buscando no se encuentra</span>
              <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
              <p class="mb-2">Parece que te has perdido en la red.</p>
              <p class="mb-6">Por favor, vuelve a intentarlo o contáctanos si crees que hay un error.</p>
              <v-btn color="primary" to="/">Volver al inicio</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  }
  </script>
  
  <style scoped>
  .v-card-title {
    background-color: #fff;
  }
  .v-card {
    width: 100%;
    max-width: 600px;
  }

  .fill-height {
  height: 100vh;
}

.text-center {
  text-align: center;
}

.v-icon {
  color: #f44336;
  margin-bottom: 20px;
}
  </style>
  