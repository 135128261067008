<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Permisos del Sistema </span>
              </v-col>

              <v-btn to="/create-permission" color=" mr-2">
                <v-icon left> mdi-key-chain-variant </v-icon>
                Registrar Permiso
              </v-btn>
            </v-row>
          </v-toolbar>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="permissions"
              :search="search"
              :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }"
              no-data-text="No hay datos disponibles"
              no-results-text="No hay datos disponibles"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon :to="'/update-permission/' + item.id">
                      <v-icon v-bind="attrs" color="primary" v-on="on">
                        mdi-pencil-box-multiple
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Modificar</span>
                </v-tooltip>

                <v-dialog transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                      <v-card-text>
                        <div class="text-h7 pa-6">
                          ¿Estás seguro que deseas eliminar el permiso?
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          class="primary"
                          text
                          @click="
                            dialog.value = false;
                            deleteItem(item);
                          "
                          >Aceptar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      headers: [
       
        { text: "Permiso", value: "name" },
        { text: "Módulo", value: "module" },
        { text: "Descripción", value: "description" },
        { text: "Acciones", value: "actions" },
      ],
      permissions: [],
    };
  },

  mounted() {
    this.action = "Eliminar";
    this.getPermissions();
  },

  methods: {
    deleteItem(item) {
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/permission-destroy", request)
        .then(() => {
          this.getPermissions();
          this.displayNotification("success", "Éxito", "Permiso eliminado");
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el Permiso"
          );
        });
    },
    getPermissions() {
      axios
        .get("/permission-index")
        .then((response) => {
          console.log(response.data);
          this.permissions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los Permisos"
          );
        });
    },
  },
};
</script>
