var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('vue-snotify'),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"mt-2 mb-4"},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" Credenciales Duplicadas")])]),_c('v-btn',{attrs:{"color":" mr-2"},on:{"click":_vm.exportToExcel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Exportar Excel ")],1),_c('v-btn',{attrs:{"color":" mr-2"},on:{"click":_vm.generateReport}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-pdf-box ")]),_vm._v(" Exportar PDF ")],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"return-object":"","items":_vm.typeUsers,"item-text":"name","item-value":"value","label":"Selecione el tipo de persona"},model:{value:(_vm.typeUser),callback:function ($$v) {_vm.typeUser=$$v},expression:"typeUser"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getCredencials()}}},[_vm._v(" Buscar ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.credencials,"search":_vm.search,"footer-props":{
              itemsPerPageText: 'Filas por página',
              itemsPerPageAllText: 'todos',
            },"no-data-text":"No hay datos disponibles","no-results-text":"No hay datos disponibles"},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getNameByRut(item.rut)))])]}},{key:`item.credencial_type`,fn:function({ item }){return [(item.credencial_type_id == 1)?_c('span',[_vm._v("Alumno(a)")]):_vm._e(),(item.credencial_type_id == 2)?_c('span',[_vm._v("Funcionario(a)")]):_vm._e(),(item.credencial_type_id == 3)?_c('span',[_vm._v("Visitante")]):_vm._e()]}},{key:`item.status_id`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getNameStatusById(item.status_id)))])]}}],null,true)}),_c('div',{ref:"testHtml",attrs:{"id":"app"}},[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":"reporte","pdf-quality":2,"manual-pagination":false,"pdf-format":"a4"},on:{"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasGenerated":function($event){return _vm.hasGenerated($event)}}},[_c('div',{staticClass:"pl-10 pt-10",attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('br'),_c('br'),_c('br'),_c('v-row',[_c('v-col',{staticClass:"pt-10",attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":"https://www.pumahue.cl/puerto-montt/wp-content/uploads/sites/5/2017/11/logo_Pumahue_.png"}})],1),_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Reporte de Credenciales Duplicadas ")]),_c('v-col',{attrs:{"cols":"4"}}),_c('br'),_c('br'),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"10"}},[_c('v-data-table',{ref:"dataTable",attrs:{"headers":_vm.headers,"items":_vm.credencials,"search":_vm.search,"hide-default-footer":"","footer-props":{
                        itemsPerPageText: 'Filas por página',
                        itemsPerPageAllText: 'todos',
                      },"no-data-text":"No hay datos disponibles","no-results-text":"No hay datos disponibles"},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getNameByRut(item.rut)))])]}},{key:`item.credencial_type`,fn:function({ item }){return [(item.credencial_type_id == 1)?_c('span',[_vm._v("Alumno(a)")]):_vm._e(),(item.credencial_type_id == 2)?_c('span',[_vm._v("Funcionario(a)")]):_vm._e(),(item.credencial_type_id == 3)?_c('span',[_vm._v("Visitante")]):_vm._e()]}},{key:`item.status_id`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getNameStatusById(item.status_id)))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1"}})],1)],1)])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }