<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Credenciales Duplicadas</span>
              </v-col>

              <v-btn @click="exportToExcel" color=" mr-2">
                <v-icon left> mdi-microsoft-excel </v-icon>
                Exportar Excel
              </v-btn>
             
              <v-btn @click="generateReport" color=" mr-2">
                <v-icon left> mdi-file-pdf-box </v-icon>
                Exportar PDF
              </v-btn>
            </v-row>
          </v-toolbar>

          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="12" md="4">
                <v-select
                  v-model="typeUser"
                  return-object
                  :items="typeUsers"
                  item-text="name"
                  item-value="value"
                  label="Selecione el tipo de persona"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="mt-2">
                <v-btn color="primary" @click="getCredencials()">
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="credencials"
              :search="search"
              :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }"
              no-data-text="No hay datos disponibles"
              no-results-text="No hay datos disponibles"
            >
              <template v-slot:[`item.name`]="{ item }">
                <span>{{ getNameByRut(item.rut) }}</span>
              </template>
              <template v-slot:[`item.credencial_type`]="{ item }">
                <span v-if="item.credencial_type_id == 1">Alumno(a)</span>
                <span v-if="item.credencial_type_id == 2">Funcionario(a)</span>
                <span v-if="item.credencial_type_id == 3">Visitante</span>
              </template>
              <template v-slot:[`item.status_id`]="{ item }">
                <span>{{ getNameStatusById(item.status_id) }}</span>
              </template>

              <!--
                <template v-slot:[`item.status_name`]="{ item }">
                <span>{{ getStatusById(item.status_id) }}</span>
              </template>

              <template v-slot:[`item.name`]="{ item }">
                <span>{{
                  getNameByIdTypeRut(item.credencial_type_id, item.rut)
                }}</span>
              </template>
                <template v-slot:[`item.name`]="{ item }">
                <span>{{
                  getNameByIdTypeRut(item.credencial_type_id, item.rut)
                }}</span>
              </template>
              -->
            </v-data-table>
            <div id="app" ref="testHtml">
              <vue-html2pdf
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="reporte"              
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
              >
                <div slot="pdf-content" class="pl-10 pt-10">
                  <br /><br /><br />
                  <v-row>
                    <v-col cols="4" class="pt-10"></v-col>
                    <v-col cols="4">         <v-img
          src="https://www.pumahue.cl/puerto-montt/wp-content/uploads/sites/5/2017/11/logo_Pumahue_.png"
        ></v-img></v-col>
                    <v-col cols="4"></v-col>

                    <v-col cols="4" class="pt-10"></v-col>
                    <v-col cols="4">Reporte de Credenciales Duplicadas
              
                    </v-col>
                    <v-col cols="4"></v-col>
                    <br /><br />
                    <v-col cols="1"></v-col>

                    <v-col cols="10">
                      <v-data-table
                        :headers="headers"
                        :items="credencials"
                        :search="search"
                        hide-default-footer
                        :footer-props="{
                          itemsPerPageText: 'Filas por página',
                          itemsPerPageAllText: 'todos',
                        }"
                        ref="dataTable"
                        no-data-text="No hay datos disponibles"
                        no-results-text="No hay datos disponibles"
                      >
                        <template v-slot:[`item.name`]="{ item }">
                          <span>{{ getNameByRut(item.rut) }}</span>
                        </template>
                        <template v-slot:[`item.credencial_type`]="{ item }">
                          <span v-if="item.credencial_type_id == 1"
                            >Alumno(a)</span
                          >
                          <span v-if="item.credencial_type_id == 2"
                            >Funcionario(a)</span
                          >
                          <span v-if="item.credencial_type_id == 3"
                            >Visitante</span
                          >
                        </template>
                        <template v-slot:[`item.status_id`]="{ item }">
                          <span>{{ getNameStatusById(item.status_id) }}</span>
                        </template>
                      </v-data-table></v-col
                    >
                    <v-col cols="1"></v-col>
                  </v-row>
                </div>
              </vue-html2pdf>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import * as XLSX from 'xlsx';



export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  components: {
    VueHtml2pdf,
  },

  data() {
    return {
      visible: false,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      typeUser: "",
      show: true,
      /* $table->string("token",100);


            $table->unsignedBigInteger("status_id");
            $table->foreign("status_id")*/

      headers: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Tipo de Credencial", value: "credencial_type" },
        { text: "Estado", value: "status_id" },
        { text: "Fecha Inicial", value: "start_date" },
        { text: "Fecha Final", value: "end_date" },
      ],

      headers2: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Fecha Inicial", value: "start_date" },
        { text: "Fecha Final", value: "end_date" },
        { text: "Estado", value: "status_id" },
        { text: "Tipo de Credencial", value: "credencial_type" },
      ],

      state: "",
      credencials: [],
      status: [],
      credencial_types: [],
      persons: [],
      typeUsers: [
        {
          name: "Alumno(a)",
          value: 1,
        },
        {
          name: "Funcionario(a)",
          value: 2,
        },
        {
          name: "Visitante",
          value: 3,
        },
      ],
    };
  },

  mounted() {
    this.action = "Eliminar";
  },

  methods: {

    exportToExcel() {
      const worksheet = XLSX.utils.table_to_sheet(this.$refs.dataTable.$el);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      XLSX.writeFile(workbook, 'students.xlsx');
    },

    saveAsExcelFile(buffer, fileName) {
      const data = new Blob([buffer], { type: 'application/octet-stream' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(data);
      downloadLink.download = fileName;
      downloadLink.click();
    },

    getNameByRut(rut) {
      let person = this.persons.find((p) => p.rut === rut);
      return (
        person.name + " " + person.last_name + " " + person.mother_last_name
      );
    },

    getNameStatusById() {
      return this.status.name;
    },
    generateReport() {

      // Obtén la referencia al contenedor del contenido del reporte
      const reportContent = this.$refs.html2Pdf;

      // Crea el objeto de configuración para html2pdf
      const config = {
        filename: 'reporte.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        margin: [0, 60, 0, 0], // Margen superior para dar espacio al encabezado de la imagen
        jsPDF: { format: 'a4', orientation: 'portrait' }
      };

      // Exporta el contenido del reporte como PDF utilizando html2pdf
      html2pdf().from(reportContent).set(config).save();
    //  this.$refs.html2Pdf.generatePdf();
    },


    generateTableContent() {
      const tableRows = [];

      // Agrega las filas de la tabla
      for (let i = 0; i < this.credencials.length; i++) {
        const row = [];

        // Agrega las celdas de la fila
        for (let j = 0; j < this.headers.length; j++) {
          const key = this.headers[j].value;
          const value = this.credencials[i][key];
          row.push(value);
        }

        tableRows.push(`<tr><td>${row.join("</td><td>")}</td></tr>`);
      }

      return `<table><thead><tr><th>${this.headers
        .map((header) => header.text)
        .join("</th><th>")}</th></tr></thead><tbody>${tableRows.join(
        ""
      )}</tbody></table>`;
    },

    exportTableToPdf() {
      const tableContent = this.generateTableContent();
      const element = document.createElement("div");
      element.innerHTML = tableContent;

      html2pdf(element, {
        margin: 1,
        filename: "tabla.pdf",
        image: { type: "jpg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      });
    },

    getStatusById(status_id) {
      let state = this.status.find((s) => s.id === status_id);
      return state.name;
    },
    getCredencialTypeById(credencial_type_id) {
      let credencial_type = this.credencial_types.find(
        (c) => c.id === credencial_type_id
      );
      return credencial_type.name;
    },

    getCredencials() {
      let request = {
        user_type: this.typeUser.value,
      };
      console.log("entra a buscar credenciales");
      axios
        .post("/credential-duplicated", request)
        .then((response) => {
          this.credencials = response.data.credentials;
          this.persons = response.data.persons;
          this.status = response.data.status;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las credenciales"
          );
        });
    },

    getStatus() {
      axios
        .get("/status-index")
        .then((response) => {
          this.status = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    getCredencialTypes() {
      axios
        .get("/credencial-type-index")
        .then((response) => {
          this.credencial_types = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    deleteItem(item) {
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/credential-destroy", request)
        .then(() => {
          this.displayNotification("success", "Éxito", "Credencial Eliminada");
          this.getCredencials();
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la credencial"
          );
        });
    },
  },
};
</script>
