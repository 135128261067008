<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Informaci&oacute;n por curso</span>
              </v-col>

              <v-btn @click="exportToExcel" color=" mr-2">
                <v-icon left> mdi-microsoft-excel </v-icon>
                Exportar Excel
              </v-btn>
              <v-btn @click="generateReport" color=" mr-2">
                <v-icon left> mdi-file-pdf-box </v-icon>
                Exportar PDF
              </v-btn>
            </v-row>
          </v-toolbar>
          <v-card class="mt-3">
            <v-row>
              <v-col cols="12" md="3" class="ml-3 mt-3">
                <v-select
                  v-model="grade"
                  no-data-text="No hay datos disponibles"
                  :items="grades"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-alpha-c-circle"
              
                  label="Cursos *"
                  persistent-hint
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3" class="ml-3 mt-3">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dates"
                      label="Rango de Fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" range locale="es-cl">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" class="ml-3 mt-5">
                <v-btn color="primary" @click="getInform()"> Buscar </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-divider class="mb-3"></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <p>
                  <strong class="mr-3">Total de Entradas :</strong>
                  {{ (this.time_entry + this.late_check_in) }}
                </p>
                <p>
                  <strong class="mr-3">Total de salidas: </strong
                  >{{this.departure_on_time +this.late_departure}}
                </p>
                
              </v-col>
              <v-col cols="4">
                <p>
                  <strong class="mr-3">Entradas en tiempo:</strong>
                  {{ this.time_entry }}
                </p>
                <p>
                  <strong class="mr-3">Entradas retrasadas</strong
                  >{{ this.late_check_in }}
                </p>
              </v-col>
              <v-col cols="4">
                <p>
                  <strong class="mr-3">Salidas en tiempo:</strong
                  >{{ this.departure_on_time }}
                </p>
                <p>
                  <strong class="mr-3">Salidas anticipadas:</strong
                  >{{ this.late_departure }}
                </p>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12"> </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="registers"
              :search="search"
              :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }"
              no-data-text="No hay datos disponibles"
              no-results-text="No hay datos disponibles"
              class="justify-center"
            >
               <template v-slot:[`item.id_student`]="{ item }">
                          <span>{{ getName(item.id_student) }}</span>
                        </template>

              <template v-slot:[`item.id_entrance`]="{ item }">
                <span>{{ getNameEntranceById(item.id_entrance) }}</span>
              </template>

              <template v-slot:[`item.id_action`]="{ item }">
                <span>{{ getNameActionById(item.id_action) }}</span>
              </template>

              <template v-slot:[`item.on_time`]="{ item }">
                <span v-if="item.on_time == 1" class="justify-center">
                  <v-chip class="ma-2" color="green" text-color="white">
                    En tiempo
                  </v-chip>
                </span>
                <span v-if="item.on_time != 1" class="justify-center">
                  <v-chip class="ma-2" color="red" text-color="white">
                    Retraso
                  </v-chip>
                </span>
              </template>
              <template v-slot:[`item.gruop_id`]>
                <span>{{ grade_name }}</span>
              </template>

              <!-- 
<template v-slot:[`item.id_action`]="{ item }">
                <span>{{ getActionById(item.id_action) }}</span>
              </template>
              <template v-slot:[`item.id_entrance`]="{ item }">
                <span>{{ getAccessById(item.id_entrance) }}</span>
              </template>
              <template v-slot:[`item.type_person`]>
                <span>Visita</span>
              </template>
            -->
            </v-data-table>
            <div id="app" ref="testHtml">
              <vue-html2pdf
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="reporte"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
              >
                <div slot="pdf-content" class="pl-10 pt-10">
                  <br /><br /><br />
                  <v-row>
                    <v-col cols="4" class="pt-10"></v-col>
                    <v-col cols="5"
                      >Reporte de accesos por tipo de personas
                    </v-col>
                    <v-col cols="3"></v-col>
                    <br /><br />

                    <v-col cols="1"></v-col>
                    <v-col cols="2">
                      <p>Entradas en tiempo : {{ " " + this.time_entry }}</p>
                    </v-col>
                    <v-col cols="2">
                      <p>
                        Entradas retrasadas : {{ " " + this.late_check_in }}
                      </p>
                    </v-col>
                    <v-col cols="2">
                      <p>
                        Salidas en tiempo : {{ " " + this.departure_on_time }}
                      </p>
                    </v-col>
                    <v-col cols="2">
                      <p>Salidas retrasadas: {{ " " + this.late_departure }}</p>
                    </v-col>
                    <v-col cols="3"></v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="10">
                      <v-data-table
                        :headers="headers"
                        :items="registers"
                        :search="search"
                        hide-default-footer
                        :footer-props="{
                          itemsPerPageText: 'Filas por página',
                          itemsPerPageAllText: 'todos',
                        }"
                        no-data-text="No hay datos disponibles"
                        no-results-text="No hay datos disponibles"
                      >
                   
                      <template v-slot:[`item.id_student`]="{ item }">
                          <span>{{ getName(item.id_student) }}</span>
                        </template>

                        <template v-slot:[`item.id_entrance`]="{ item }">
                          <span>{{
                            getNameEntranceById(item.id_entrance)
                          }}</span>
                        </template>

                        <template v-slot:[`item.id_action`]="{ item }">
                          <span>{{ getNameActionById(item.id_action) }}</span>
                        </template>

                        <template v-slot:[`item.on_time`]="{ item }">
                          <span v-if="item.on_time == 1" class="justify-center">
                            <v-chip
                              class="ma-2"
                              color="green"
                              text-color="white"
                            >
                              En tiempo
                            </v-chip>
                          </span>
                          <span v-if="item.on_time != 1" class="justify-center">
                            <v-chip class="ma-2" color="red" text-color="white">
                              Retraso
                            </v-chip>
                          </span>
                        </template>
                        <template v-slot:[`item.gruop_id`]>
                          <span>{{ grade_name }}</span>
                        </template>
                      </v-data-table></v-col
                    >
                    <v-col cols="1"></v-col>
                  </v-row>
                </div>
              </vue-html2pdf>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";

import * as XLSX from 'xlsx'  

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  components: {
    VueHtml2pdf,
  },

  data() {
    return {
      visible: false,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      access: "",
      accesses: [],
      dates: ["", ""],
      date: ["", ""],
      grade: "",
      grades: [],
      grade_name: "",
      data: {},
      total_entrances: 0,
      total_exits: 0,
      students: [],
      entrances: [],
      actions: [],
      time_entry: 0,
      late_check_in: 0,
      departure_on_time: 0,
      late_departure: 0,

      typesPerson: [
        { value: "Funcionario" },
        { value: "Alumno" },
        { value: "Visita" },
      ],

      headers: [
        { text: "Rut", value: "rut" },
        { text: "Nombre", value: "id_student" },
        { text: "Grupo", value: "gruop_id" },
        { text: "Puerta", value: "id_entrance" },
        { text: "Accion", value: "id_action" },
        { text: "Horario", value: "on_time" },
        { text: "Fecha", value: "date" },
      ],

      headers2: [
        { text: "Rut", value: "rut_visit" },
        { text: "Accion", value: "id_action" },
        { text: "Puerta", value: "id_entrance" },
        { text: "Fecha", value: "date" },
      ],

      state: "",
      registers: [],
      status: [],
      credencial_types: [],
    };
  },

  mounted() {
    this.action = "Eliminar";
    this.getGrades();
  },

  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

      exportToExcel() {
      const worksheet = XLSX.utils.table_to_sheet(this.$refs.html2Pdf.$el);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      XLSX.writeFile(workbook, 'report.xlsx');
    },
    generateTableContent() {
      const tableRows = [];

      // Agrega las filas de la tabla
      for (let i = 0; i < this.credencials.length; i++) {
        const row = [];
        // Agrega las celdas de la fila
        for (let j = 0; j < this.headers.length; j++) {
          const key = this.headers[j].value;
          const value = this.credencials[i][key];
          row.push(value);
        }
        tableRows.push(`<tr><td>${row.join("</td><td>")}</td></tr>`);
      }
      return `<table><thead><tr><th>${this.headers
        .map((header) => header.text)
        .join("</th><th>")}</th></tr></thead><tbody>${tableRows.join(
        ""
      )}</tbody></table>`;
    },

    exportTableToPdf() {
      const tableContent = this.generateTableContent();
      const element = document.createElement("div");
      element.innerHTML = tableContent;

      html2pdf(element, {
        margin: 1,
        filename: "tabla.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      });
    },
    getNameByGradeId(student_id) {
      return student_id;
    },
    getAccessById(access_id) {
      let access = this.accesses.find((a) => a.id === access_id);
      return access.name;
    },
    getActionById(action_id) {
      let action = this.actions.find((a) => a.id === action_id);
      return action.name;
    },
    getName(student_id) {
      console.log(this.students)
      let student = this.students.find((s) => s.id === student_id);  

    if(student=== undefined)
    {
      return ""
    }
    else
      return student.name;
    },
    getNameEntranceById(entrance_id) {
      let entrance = this.entrances.find((a) => a.id === entrance_id);
      return entrance.name;
    },
    getNameActionById(action_id) {
      let action = this.actions.find((a) => a.id === action_id);
      return action.name;
    },
    getGrades() {
      axios
        .get("/grade-index")
        .then((response) => {
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las credenciales"
          );
        });
    },

    getActions() {
      axios
        .get("/action-index")
        .then((response) => {
          this.actions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las credenciales"
          );
        });
    },

    getStatus() {
      axios
        .get("/status-index")
        .then((response) => {
          this.status = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },
    getAccess() {
      axios
        .get("/entrance-index")
        .then((response) => {
          this.accesses = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    getInform() {
      this.data.id = this.grade;
      this.data.startDate = this.dates[0];
      this.data.endDate = this.dates[1];
      axios
        .post("/information-by-grades", this.data)
        .then((response) => {
          console.log("imprime resultado de busqueda");
          console.log(response.data);
          this.students = response.data.students;
          console.log("estudiantes..");
          console.log(this.students);
          this.registers = response.data.result;         
        
          this.entrances = response.data.doors;
          this.actions = response.data.actions;
          this.grade_name = response.data.grade.name;
          this.time_entry = response.data.time_entry;
          this.late_check_in = response.data.late_check_in;
          this.departure_on_time = response.data.departure_on_time;
          this.late_departure = response.data.late_departure;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    deleteItem(item) {
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/credential-destroy", request)
        .then(() => {
          this.displayNotification("success", "Éxito", "Credencial Eliminada");
          this.getCredencials();
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la credencial"
          );
        });
    },
  },
};
</script>
