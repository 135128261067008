<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Asignar permisos a roles</span>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-row class="mt-4 ml-4">
              <v-col cols="12" md="4">
                <v-select
                  prepend-icon="mdi-file-key"
                  v-model="selected"
                  item-text="name"
                  item-value="id"
                  label="Seleccionar Rol"
                  :items="roles"
                  variant="underlined"
                  @change="getPermissionsBydIdRol()"
                ></v-select>
              </v-col>
            </v-row>
            <template>
              <v-card-text>
             

                <template>
  <v-data-table :headers="headers" :items="permissions" item-key="id" :footer-props="{
                            itemsPerPageText: 'Filas por página',
                            itemsPerPageAllText: 'todos',
                        }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
//okok
    <template v-slot:[`item.select`]="{ item }"> 
      <v-checkbox
                    v-model="permissions_selected"
                    
                    :value="item.name"
                  ></v-checkbox>
    </template>
   
   
  </v-data-table>
</template>

             
</v-card-text>

             
            </template>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/role" text> Cancelar </v-btn>

                <v-btn
                  color="primary"
                  :disabled="!valid"
                  @click="addPermissionsToRol()"
                >
                  Aceptar
                </v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      name: "",
      data: {},
      valid: true,
      roles: [],
      selected: "",

      headers: [               
                { text: "", value: "select" },
                { text: "Permiso", value: "name" },
                { text: "Módulo", value: "module" },  
                { text: "Descripción", value: "description" },              
            ],
      permissions: [],
      permissions_selected: [],
      nameRules: [
        (v) => !!v || "El nombre del Rol es requerido",
        (v) =>
          (v && v.length <= 19) ||
          "El nombre del Rol debe tener menos de 20 caracteres",
      ],
    };
  },
  mounted() {
    this.getRoles();
    this.getPermissions();
  },
  methods: {
    getPermissionsBydIdRol() {
      this.data.id = this.selected;
      this.permissions_selected = [];
      axios
        .post("/permissions-by-rol", this.data)
        .then((response) => {
          console.log("imprime permisios del rol");
          response.data.forEach((element) =>
            this.permissions_selected.push(element.name)
          );
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener permisos del rol seleccionado"
          );
        });
    },
    addPermissionsToRol() {
      this.data.id = this.selected;
      this.data.permissions = this.permissions_selected;
      console.log("id de rol " + this.selected);
      console.log("permisos de rol " + this.permissions_selected);
      (this.valid = false),
        axios
          .post("/add-permissions-to-rol", this.data)
          .then(() => {
            this.displayNotification("success", "Éxito", "Permisos asociados satisfactoriamente");
            setTimeout(() => this.$router.push({ name: "role" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Error al permisos al rol"
            );
          });
    },
    getRoles() {
      axios
        .get("/role-index")
        .then((response) => {
          console.log(response.data);
          this.roles = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los Permisos"
          );
        });
    },
    getPermissions() {
      axios
        .get("/permission-index")
        .then((response) => {
          console.log(response.data);
          this.permissions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los Permisos"
          );
        });
    },
  },
};
</script>
