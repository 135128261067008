<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow ">
                  <span style="color: white;"> Modificar Horario</span>
                </v-col>

              </v-row>
            </v-toolbar>
            <v-row class="mt-4 ml-4">
              <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-calendar" v-model="name" :rules="nameRules"
                  label="Nombre del Horario *" type="text"></v-text-field></v-col>
              <v-col cols="12" md="4">
                <v-select no-data-text="No hay datos disponibles" :items="grades" item-text="name" v-model="grade_id"
                  :disabled="true" item-value="id" prepend-icon="mdi-account-details" label="Curso *" persistent-hint>
                </v-select>
              </v-col>




            </v-row>
            <v-divider class="mb-3"></v-divider>

            <v-row>

              <v-col cols="12" md="9" class="pl-6">
                <template>
                  <v-row class="fill-height">
                    <v-col>
                      <v-sheet height="64">
                        <v-toolbar flat>
                          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                            Hoy
                          </v-btn>
                          <v-btn fab text small color="grey darken-2" @click="prev">
                            <v-icon small>
                              mdi-chevron-left
                            </v-icon>
                          </v-btn>
                          <v-btn fab text small color="grey darken-2" @click="next">
                            <v-icon small>
                              mdi-chevron-right
                            </v-icon>
                          </v-btn>
                          <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-menu bottom right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>
                                  mdi-menu-down
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="type = 'day'">
                                <v-list-item-title>Diario</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="type = 'week'">
                                <v-list-item-title>Semanal</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="type = 'month'">
                                <v-list-item-title>Mensual</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-toolbar>
                      </v-sheet>
                      <v-sheet height="600">
                        <v-calendar ref="calendar" v-model="focus" color="primary" :events="events"
                          :event-color="getEventColor" :type="type" @click:event="showEvent" @click:more="viewDay"
                          @click:date="viewDay"></v-calendar>
                        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement"
                          offset-x>
                          <v-card color="" min-width="350px" flat>
                            <v-toolbar :color="selectedEvent.color" dark>

                              <v-toolbar-title> Eliminar evento</v-toolbar-title>
                              <v-spacer></v-spacer>

                            </v-toolbar>
                            <v-card-text>
                              <span>
                                Estás seguro que deseas eliminar el evento : {{ selectedEvent.name }}

                              </span>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn @click="selectedOpen = false">
                                Cancelar
                              </v-btn>

                              <v-btn prepend-icon="mdi-delete" color="primary" @click="deleteItem(events.indexOf(selectedEvent));
                              selectedOpen = false">
                                <v-icon>
                                  mdi-delete
                                </v-icon>
                                Eliminar
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
              <v-col cols="12" md="3" class="  pt-12">

                <template>
                  <v-card class="mx-auto mt-7 pl-2" elevation="6">
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline">
                          Eventos
                        </div>
                        <v-text-field v-model=" name_event " label="Nombre del evento" required></v-text-field>

                        <v-select v-model=" ex7 " return-object :items=" actions " item-text="name" item-value="value"
                          label="Selecione el tipo de acción">
                        </v-select>

                        <v-menu v-model=" menu1 " :close-on-content-click=" false " transition="scale-transition" offset-y
                          max-width="290px" min-width="auto">
                          <template v-slot:activator=" { on, attrs } ">
                            <v-text-field v-model=" date " label="Seleccione la fecha" 
                              persistent-hint prepend-icon="mdi-calendar" readonly v-bind=" attrs "
                              v-on=" on "></v-text-field>
                          </template>
                          <v-date-picker v-model=" date " no-title @input=" menu1 = false " locale="es-cl"
                          :min=" (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) "
                           ></v-date-picker>
                        </v-menu>

                        <v-menu ref="menu2" v-model=" menu2 " :close-on-content-click=" false " :nudge-right=" 40 "
                          :return-value.sync=" time_start " transition="scale-transition" offset-y max-width="290px"
                          min-width="290px">
                          <template v-slot:activator=" { on, attrs } ">
                            <v-text-field v-model=" time_start " label="Hora de Inicio"
                              prepend-icon="mdi-clock-time-four-outline" readonly v-bind=" attrs " v-on=" on "></v-text-field>
                          </template>
                          <v-time-picker v-if=" menu2 " v-model=" time_start " full-width
                            @click:minute=" $refs.menu2.save(time_start) "></v-time-picker>
                        </v-menu>

                        <v-menu ref="menu3" v-model=" menu3 " :close-on-content-click=" false " :nudge-right=" 40 "
                          :return-value.sync=" time_end " transition="scale-transition" offset-y max-width="290px"
                          min-width="290px">
                          <template v-slot:activator=" { on, attrs } ">
                            <v-text-field v-model=" time_end " label="Hora de Fin"
                              prepend-icon="mdi-clock-time-four-outline" readonly v-bind=" attrs " v-on=" on "></v-text-field>
                          </template>
                          <v-time-picker v-if=" menu3 " v-model=" time_end " full-width
                            @click:minute=" $refs.menu3.save(time_end) "></v-time-picker>
                        </v-menu>

                      </v-list-item-content>

                    </v-list-item>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="btn primary" text @click=" addEvent() ">
                        Agregar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-col>
            </v-row>

            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/schedule" text>
                  Cancelar
                </v-btn>

                <v-btn color="primary" :disabled=" !valid " @click=" create() ">
                  Aceptar
                </v-btn></v-col>
            </v-row>
          </v-form>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      ex7: "",
      id_calendar:0,
      name: "",
      name_event: "",
      time_start: null,
      time_end: null,
      menu2: false,
      menu3: false,
      menu1: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      data: {},
      valid: true,
      region: "",
      regions: [],
      grades: [],
      grade_id: "",
      actions: [],
      nameRules: [
        v => !!v || 'El Nombre del Horario es requerido',
        v => (v && v.length <= 49) || 'El nombre del horario debe tener menos de 50 caracteres',
      ],
      nameGroup: [
        v => !!v || 'Debe seleccionar el curso',
       
      ],


      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mensual',
        week: 'Semanal',
        day: 'Diario',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    }
  },

  mounted() {

    this.chargeData()
    this.getGrades()
    this.getActions()

  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },

  watch: {
    /* eslint-disable */
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },

  methods: {

    chargeData() {
      console.log("cargando datos");
      this.id_calendar =this.$route.params.id
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/calendar-show", request)
        .then((response) => {
          console.log(response.data);
          this.name = response.data.name;
          this.grade_id = response.data.grade_id;

          response.data.events.forEach((event) => {

            this.events.push({
              name: event.name,
              start:new Date( event.time_init),
              end: new Date( event.time_end),
              color: event.color,
              timed: true,
              type: event.type,
              time_start: event.time_init.split(' ')[1],
              time_end: event.time_end.split(' ')[1],
            })
          });
          console.log(this.events);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al cargar el estado");
        });
    },
    getGrades() {
      axios
        .get("/grade-index")
        .then((response) => {
          console.log(response.data)
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los grupos"
          );
        });
    },

    getActions() {
      axios
        .get("/action-index")
        .then((response) => {
          console.log(response.data)
          this.actions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los tipos de acciones"
          );
        });
    },

    deleteItem(index) {

      try {
        this.events.splice(index, 1)
        this.displayNotification(
          "success",
          "Exito",
          "Evento eliminado correctamente"
        );

      } catch (error) {
        this.displayNotification(
          "error",
          "Error",
          "Error al eliminar el evento del calendario"
        );
      }

    },

    create() {
      let error =false;
      
      if (this.events.length == 0 || this.events ==null) {
        error = true;
        this.displayNotification(
          "error",
          "Error",
          "Debe agregar al menos un evento al calendario"
        );
      }
  if (!error) {
    this.data.id= this.id_calendar;
      this.data.name = this.name;
      this.data.grade_id = this.grade_id;
      this.data.type = "Luupa";
      this.data.events = this.events;

      console.log(this.events)
      this.data.date = this.date;
      console.log(this.data)

      this.valid = false,
        axios
          .post("/calendar-update", this.data)
          .then(() => {
            this.displayNotification(
              "success",
              "Éxito",
              "Horario modificado"
            );
            setTimeout(() => this.$router.push({ name: "schedule" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Error al modificar el Horario"
            );
          });
  }
      
    },

    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },


    addEvent() {
      let error = false;
      if (this.ex7 == "" || this.ex7 == null) {
        error = true;
        this.displayNotification(
          "error",
          "Error",
          "Debe seleccionar el tipo de acción"
        );
      }

      if (this.name_event == "" || this.name_event == null) {
        error = true;
        this.displayNotification(
          "error",
          "Error",
          "El nombre del evento debe ser válido"
        );
      }
      if (this.date == "" || this.date == null) {
        error = true;
        this.displayNotification(
          "error",
          "Error",
          "La fecha debe de ser válida"
        );
      }

      if ((this.time_start == "" || this.time_start == null)) {
        error = true;
        this.displayNotification(
          "error",
          "Error",
          "La hora de inicio debe de ser válida"
        );
      }

      if (this.time_end == "" || this.time_end == null) {
        error = true;
        this.displayNotification(
          "error",
          "Error",
          "La hora de fin debe de ser válida"
        );
      }

      let start = new Date(this.date + " " + this.time_start)
      let end = new Date(this.date + " " + this.time_end)

      console.log(this.events)

      this.events.forEach((event, index) => {

        if ((event.start <= start && start <= event.end) || (event.start <= end && end <= event.end)) {
          error = true
          this.displayNotification(
            "error",
            "Error",
            "Existe un evento programado en el rango de horario especificado"
          );
        }
      });

      if (!error) {
        // alert(this.ex7.name)
        let color = "grey"
        if (this.ex7.name == "") {
          color = "blue"
        }
        else
          if (this.ex7.name == "Entrada") {
            color = "blue"
          }
          else
            if (this.ex7.name == "Salida") {
              color = "green"
            }
            else {
              color = "red"
              start = new Date(this.date + " 00:00")
              end = new Date(this.date + " 23:59")
            }

        this.events.push({
          name: this.name_event,
          start: start,
          end: end,
          color: color,
          timed: true,
          type: this.ex7.id,
          time_start: this.time_start,
          time_end: this.time_end
        })
      }

    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },


  },

};
</script>