<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">

          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow ">

                <span style="color: white;"> Listado de Cursos </span>
              </v-col>

              <v-btn to="/create-grade" color=" mr-2">
                <v-icon left>
                  mdi-door-open
                </v-icon>
                Registrar Curso
              </v-btn>

            </v-row>
          </v-toolbar>
          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="5" class="ml-10">
              <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Buscar" single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-card-text> <v-data-table :headers="headers" :items="grades" :search="search" :loading="loading"
              loading-text="Cargando... Por favor espere" :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">

              <template v-slot:[`item.id_official`]="{ item }">
                <span>{{ getOfficialById(item.id_official) }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip top>
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon :to="'/update-grade/' + item.id">
                      <v-icon v-bind="attrs" color="primary" v-on="on">
                        mdi-pencil-box-multiple
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Modificar</span>
                </v-tooltip>

                <v-dialog transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                      <v-card-text>
                        <div class="text-h7 pa-6">
                          ¿Estás seguro que deseas eliminar el curso?
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                        <v-btn class="primary" text @click="
                          dialog.value = false;
                        deleteItem(item);
                        ">Aceptar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </template>


            </v-data-table>

          </v-card-text>

        </v-card>
      </v-col>


    </v-row>

  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      loading: true,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      headers: [

        { text: "Curso", value: "name" },
        { text: "Profesor", value: "id_official", filterable: false },
        { text: "Acciones", value: "actions" },


      ],

      grades: [],
      officials: [],

    }
  },



  mounted() {
    this.action = "Eliminar"
    this.getOfficials()
    this.getGrades()


  },

  methods: {

    deleteItem(item) {

      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/grade-destroy", request)
        .then(() => {
          this.getGrades()
          this.displayNotification("success", "Éxito", "Curso eliminado");

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el curso"
          );
        });
    },

    getOfficialById(id) {
      let official = this.officials.find((c) => c.id === id);
      let full_name = ""
      if (official) {
        full_name = official.name + " " + official.last_name + " " + official.mother_last_name;
      }
      return full_name;
    },

    getGrades() {
      axios
        .get("/grade-index")
        .then((response) => {
          console.log(response.data)
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los grupos"
          );
        })
        .finally(() => {

          this.loading = false
        });
    },
    getOfficials() {
      axios
        .get("/official-index")
        .then((response) => {
          console.log(response.data)
          this.officials = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los funcionarios"
          );
        });
    },


  },
};
</script>