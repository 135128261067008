<template>
  <div>
    <vue-snotify></vue-snotify>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">

          <v-toolbar id="bar-login" dark color="primary">
            Control de Acceso
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-row class="mt-2">

            <v-col cols="10" md="10">
              <v-text-field class="ml-12" prepend-icon="mdi-qrcode-scan" v-model="scanned_code"
                label="Escanear Credencial" type="password" :ref="scannerCredential"
                @keyup.enter="scannerCode()"></v-text-field>
            </v-col>

            <v-col cols="2" md="2">
              <v-btn color="primary" @click="scannerCode">
                <v-icon>mdi-check-circle</v-icon><span>Aceptar</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="4"></v-col>
            <v-col cols="4">

              <v-btn-toggle v-model="id_action" color="primary" group>
                <v-btn block x-large value="1">
                  <v-icon left>
                    mdi-location-enter
                  </v-icon>
                  <span class="hidden-sm-and-down">Entrada </span>
                </v-btn>
                <v-btn block x-large value="2">
                  <v-icon left>
                    mdi-location-exit
                  </v-icon>
                  <span class="hidden-sm-and-down">Salida</span>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="5"></v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="show">
      <v-col cols="12" md="12">
        <v-row class="mt-2">
          <v-col cols="12" md="12">
            <v-card :color="color" dark>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>

                  <v-card-title>
                    <h2>{{ user_name }}</h2>

                  </v-card-title>

                  <v-card-subtitle>
                    <h2>{{ user_type }}</h2>
                    <h2 class="mt-3">{{ user_access }}</h2>
                    <h3>RUT:{{ user_rut }}</h3>
                    <h3>Teléfono: {{ mobile }}</h3>
                    <h3>Email: {{ email }}</h3>
                    <h3>Fecha y Hora: {{ time }}</h3>
                  </v-card-subtitle>

                </div>

                <v-avatar class="ma-3" size="200" tile>
                  <v-img  :src="getImagePath()" ></v-img>
                </v-avatar>
              </div>
            </v-card>


            <v-alert class="mt-4" v-if="showMotive" v-model="alert" :color="color" border="left" elevation="2"
              colored-border icon="mdi-lock">


              <v-row align="center">
                <v-col class="grow">
                  <strong>{{ motive }}</strong>
                </v-col>

              </v-row>
            </v-alert>

            <v-alert class="mt-4" v-if="showAlert" v-model="alert" :color="color" border="left" elevation="2"
              colored-border icon="mdi-timer">


              <v-row align="center">
                <v-col class="grow">
                  <strong>[{{ type }}] </strong> - {{ message }} {{ ingreso_tardio }}
                </v-col>
                <v-col class="shrink">
                  <v-btn>
                    <v-icon left>
                      mdi-printer
                    </v-icon>
                    Imprimir Ticket
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>

            <v-alert class="mt-4" v-if="showAlert && ingreso_tardio >= 5" v-model="alert" :color="color" border="left"
              elevation="2" colored-border icon="mdi-email">
              <v-row align="center">
                <v-col class="grow">
                  <strong>[ENVIO DE NOTIFICACION] </strong> - Se ha enviado una citación al apoderado
                </v-col>

              </v-row>
            </v-alert>



          </v-col>


        </v-row>

      </v-col>
    </v-row>
  </div>
</template>
<script>

import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import { useDoorStore } from '../../store/index'
import { useInstitutionStore } from '../../store/institution'
import axios from "axios";
import printer_plugin from "@abrazasoft/thermal_printer_vuejs"

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  setup() {
    const store = useDoorStore()
    const storeInstitution = useInstitutionStore()
    return { store, storeInstitution }
  },
  data() {
    return {

    
      id_action: 0,
      late_arrivals: 0,
      scannerCredential: "",
      motive: "",
      ingreso_tardio: 0,
      drawer: true,
      user_type: "",
      email: "",
      mobile: "",
      scanned_code: "",
      user_name: "",
      user_group: "",
      user_access: "",
      user_rut: "",
      user_token: "",
      message: "",
      type: "",
      show: false,
      color: "red",
      showAlert: false,
      showMotive: false,
      date: '',
      grades: [],
      dateScanner: '',
      time: '',
      currentDate: new Date(),
      access: "",

      flagPrint: false,
    }
  },

  mounted: function () {
    this.$refs[this.scannerCredential].focus()
    this.date = this.printDate();
    this.getGrades();
    setInterval(() => {
      this.currentDate = new Date();
    }, 1000);

  },
  methods: {

    getImagePath() {
   
      return `http://localhost:8000/rut/${this.user_rut}.jpg`;
    },

    getGradeById(grade_id) {
      let grade = this.grades.find((c) => c.id === grade_id);
      if (grade == null || grade == "") return "No asignado";
      return grade.name;
    },
    printDate: function () {
      return new Date().toLocaleDateString();
    },
    printTime: function () {
      return new Date().toLocaleTimeString();
    },
    printYear: function () {
      return new Date().getFullYear();
    },
    printTimestamp: function () {
      return Date.now();
    },
    printFullDate: function () {
      return new Date();
    },

    print(nameStudent, gradeStudent, cantRetrasos) {

      let x = 1 // esto es si la intitucion tiene habilitado la impresora
      // eslint-disable-next-line
      let printer = ""
      printer_plugin.obtenerImpresoras().then((printers) => {

        printer = printers[0]
        console.log("Printer " + printers)
        var nombreImpresora = "80mm Series Printer";
        var api_key = "7d86f73d-b6f1-4a96-a465-7a837bfce724";

        if (printer && x == 1) {
          console.log("entra")
          const conector = new printer_plugin();
          conector.fontsize("2")
          conector.textaling("center")
          conector.text(nameStudent)
          conector.fontsize("1")
          conector.text("Colegio Pumahue")
          conector.text("Grado: " + gradeStudent)
          conector.text("Cantidad de Retrasos: " + cantRetrasos)
          conector.feed("3")
          conector.textaling("left")
          conector.text("Fecha: " + this.currentDate)
          conector.feed("1")
          conector.fontsize("2")
          conector.textaling("center")
          conector.barcode_128(this.currentDate)
          conector.feed("5")
          conector.cut("0")

          const resp = conector.imprimir(nombreImpresora, api_key);
          if (resp === true) {
            console.log(resp);
          } else {
            console.log("Error: " + resp)

          }

        }

      });
    },

    async imprimir(nameInstitution, nameStudent, gradeStudent, dateScanner) {
      let nombreImpresora = "80mm Series Printer";
      let api_key = "7d86f73d-b6f1-4a96-a465-7a837bfce724";


      const conector = new printer_plugin()
      conector.fontsize("2")
      conector.textaling("center")
      conector.text(nameInstitution)
      conector.fontsize("1")
      conector.text("Comprobante de Retraso")
      conector.feed("1")
      conector.textaling("left")
      conector.text("Nombre: " + nameStudent)
      conector.text("R.U.T: " + this.user_rut)
      conector.text("Curso: " + gradeStudent)
      conector.text("Retraso No: " + this.late_arrivals)
      //  conector.feed("2")
      conector.textaling("left")
      conector.text("Fecha: " + dateScanner)
      conector.fontsize("3")
      conector.feed("1")
      conector.textaling("center")
      //conector.barcode_128()                       
      conector.qr(dateScanner)
      conector.fontsize("1")
      conector.text("Luupa v1.0")
      conector.feed("5")
      conector.cut("0")

      const resp = await conector.imprimir(nombreImpresora, api_key);
      if (resp === true) {
        console.log("Problema al imprimir: " + resp)
      } else {
        console.log("Problema al imprimir: " + resp)

      }
    },
    getGrades() {
      axios
        .get("/grade-index")
        .then((response) => {
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    scanner() {
      axios
        .post("/store-scanner")
        .then(() => {
          // this.functions = response.data; 
          alert("Entra")
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las funciones"
          );
        });
    },


    scannerCode() {
      if (this.store.id == 0) {
        this.scanned_code = "";
        this.displayNotification(
          "error",
          "Error",
          "Debe configurar el Acceso"
        )

      }
      else
        if (this.id_action == 0) {
          this.scanned_code = "";
          this.displayNotification(
            "error",
            "Error",
            "Debe seleccionar si es entrada o salida"
          )
        }
        else {
          let request = {
            token: this.scanned_code,
            id_entrance: this.store.id,
            id_action: this.id_action
          };
          this.show = false


          this.flagPrint = false,
            axios
              .post("/store-scanner", request)
              .then((response) => {

                console.log("Acceso")
                console.log(response.data.access)

                if (response.data.access === "authorized") {
                  this.color = response.data.color
                  this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                  this.user_rut = response.data.person.rut
                  this.mobile = response.data.person.mobile
                  this.email = response.data.person.email
                  this.user_type = response.data.user_type
                  this.time = response.data.dateScanner
                  this.user_access = "Acceso Autorizado"
                  this.show = true
                  this.scanned_code = ""
                  this.$refs[this.scannerCredential].focus()
                }
                else
                  if (response.data.access === "no_authorized") {
                    this.color = response.data.color
                    this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                    this.user_rut = response.data.person.rut
                    this.mobile = response.data.person.mobile
                    this.email = response.data.person.email
                    this.user_type = response.data.user_type
                    this.time = response.data.dateScanner
                    this.user_access = "Acceso Denegado"
                    this.showMotive = true
                    this.motive = response.data.motive
                    this.show = true
                    this.scanned_code = ""
                    this.$refs[this.scannerCredential].focus()

                  }

                  else if (response.data.access === "authorized_late") {
                    this.color = "orange"
                    this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                    this.user_rut = response.data.person.rut
                    this.mobile = response.data.person.mobile
                    this.email = response.data.person.email
                    this.user_type = response.data.user_type
                    this.user_group = this.getGradeById(response.data.person.grade_id)
                    this.late_arrivals = response.data.person.late_arrivals
                    this.user_access = "Acceso Retrasado"
                    this.show = true

                    this.scanned_code = ""
                    this.flagPrint = true,
                      this.dateScanner = response.data.dateScanner
                    this.time = response.data.dateScanner
                    this.$refs[this.scannerCredential].focus()
                  }

                  else if (response.data.access === "authorized_early") {
                    this.color = "orange"
                    this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                    this.user_rut = response.data.person.rut
                    this.mobile = response.data.person.mobile
                    this.email = response.data.person.email
                    this.user_type = response.data.user_type
                    this.user_group = this.getGradeById(response.data.person.grade_id)
                    this.late_arrivals = response.data.person.late_arrivals
                    this.user_access = "Retiro Anticipado"
                    this.show = true

                    this.scanned_code = ""
                    this.flagPrint = false,
                      this.dateScanner = response.data.dateScanner
                    this.time = response.data.dateScanner
                    this.$refs[this.scannerCredential].focus()
                  }
                  

                  else {
                    this.color = "red"
                    this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                    this.user_rut = response.data.person.rut
                    this.mobile = response.data.person.mobile
                    this.email = response.data.person.email
                    this.user_type = response.data.user_type
                    this.user_access = "Acceso Denegado"
                    this.show = true
                    this.scanned_code = ""
                    this.$refs[this.scannerCredential].focus()
                  }

              })
              .catch((err) => {
                this.scanned_code = ""
                this.$refs[this.scannerCredential].focus()
                console.log(err, "error");
                this.displayNotification("error", "Error", "Credencial no válida");
              })
              .finally(() => {
                if (this.flagPrint) {
                  this.imprimir(this.storeInstitution.getInstitution.name, this.user_name, this.user_group, this.dateScanner)
                }

              });


        }
    },
  },
};
</script>