<template>
  <div class="home">
    <div class="text-overline ml-2"> Dashboard</div>
    <v-row>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="light-blue">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div class="text-overline">
                    <v-row>
                      <v-col cols="10"> Alumnos</v-col>
                      <v-col cols="2"><v-btn icon color="light-blue">
                          <v-icon>mdi-account-group</v-icon>
                        </v-btn></v-col>
                    </v-row>


                  </div>
                  <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                  {{ total_students }}
                  </div>

                </v-card-text>

              </v-card>

              <v-toolbar dark color="light-blue" style="margin-top: -30px; height: 70px;">

                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="cyan">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div class="text-overline">
                    <v-row>
                      <v-col cols="10"> Funcionarios</v-col>
                      <v-col cols="2"><v-btn icon color="cyan">
                          <v-icon>mdi-account-tie</v-icon>
                        </v-btn></v-col>
                    </v-row>


                  </div>
                  <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                    {{ total_officials }}
                  </div>

                </v-card-text>

              </v-card>

              <v-toolbar dark color="cyan" style="margin-top: -30px; height: 70px;">
                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>

              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="amber">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div class="text-overline">
                    <v-row>
                      <v-col cols="10"> Visitantes</v-col>
                      <v-col cols="2"><v-btn active icon color="amber">
                          <v-icon>mdi-account-arrow-right</v-icon>
                        </v-btn></v-col>
                    </v-row>


                  </div>
                  <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                    {{ total_visit }}
                  </div>

                </v-card-text>

              </v-card>

              <v-toolbar dark color="amber" style="margin-top: -30px; height: 70px;">

                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="indigo ">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div class="text-overline">
                    <v-row>
                      <v-col cols="10"> Total de Personas</v-col>
                      <v-col cols="2"><v-btn icon color="indigo ">
                          <v-icon>mdi-cog</v-icon>
                        </v-btn></v-col>
                    </v-row>


                  </div>
                  <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                    {{  total_students + total_officials + total_visit  }}
                  </div>

                </v-card-text>

              </v-card>

              <v-toolbar dark color="indigo" style="margin-top: -30px; height: 70px;">

                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  name: 'HomeView',
  mixins: [SnotifyMixin],
  VueSnotify,
  data() {
    return {
             total_students:0,
             total_officials:0,
             total_visit:0,
      
    }
  },
 
  mounted() {   
    this.getStatictic();
  },

  methods: {

    getStatictic() {
      axios
        .post("/information-total-student-official-visit")
        .then((response) => {         
          this.total_students=response.data.total_students
          this.total_officials=response.data.total_officials
          this.total_visit=response.data.total_visit
          
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar datos de la institución"
          );
        });
    },

   
  

 
 
  }
}

</script>
