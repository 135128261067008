<template>
    <div>
        <v-row>
            <vue-snotify></vue-snotify>
            <v-col cols="12" md="12">
                <v-card class="mt-2 mb-4 ">
                    <v-form pt-4 v-model="valid">
                        <v-toolbar color="primary">
                            <v-row align="center">
                                <v-col class="grow ">
                                    <span style="color: white;"> Crear Curso</span>
                                </v-col>

                            </v-row>
                        </v-toolbar>


                        <v-row class="mt-4 ml-4">
                            <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-alpha-c-circle" v-model="name"
                                    :rules="nameRules" label="Nombre del Curso *" type="text"></v-text-field></v-col>
                            <v-col cols="12" md="4">
                                
                                <v-select v-model="official" no-data-text="No hay datos disponibles" :rules="gradeRules"
                   v-bind:items="officials"
                   item-value="id" item-text="`${data.item.name}, ${data.item.name}`" prepend-icon="mdi-account-details"
                  :disabled="itemDisabled" label="Profesor *"   persistent-hint>

                  <template slot="selection" slot-scope="data">
                  {{ data.item.name}} {{data.item.last_name}}
                </template>

                <template slot="item" slot-scope="data">
                    <v-list-tile-content>
                      <v-list-tile-title v-html="`${data.item.name} ${data.item.last_name}`">
                      </v-list-tile-title>
                      
                    </v-list-tile-content>
                  </template>
                </v-select>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-3"></v-divider>
                        <v-row>
                            <v-col cols="12" md="9"></v-col>
                            <v-col cols="12" md="3">
                                <v-btn to="/grade" text>
                                    Cancelar
                                </v-btn>

                                <v-btn color="primary" :disabled="!valid" @click="create()">
                                    Aceptar
                                </v-btn></v-col>
                        </v-row>
                    </v-form>
                </v-card>

            </v-col>
        </v-row>

    </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
    mixins: [SnotifyMixin],
    VueSnotify,

    data() {
        return {
            name: "",
            official: 0,
            data: {},
            valid: true,
            officials:[],

            nameRules: [
                v => !!v || 'El nombre del curso es requerido',
                v => (v && v.length <= 19) || 'El nombre del curso debe tener menos de 20 caracteres',
            ],
            officialRules: [
                v => !!v || 'El profesor es requerido',
            ],

        }
    },

    mounted() {
        this.getOfficials()
    },

    methods: {


        getOfficials() {
            axios
                .post("/official-available-by-grade")
                .then((response) => {
                    this.officials = response.data;
                })
                .catch((err) => {
                    console.log(err, "error");
                    this.displayNotification(
                        "error",
                        "Error",
                        "Error al obtener los profesores"
                    );
                });
        },

        create() {
            this.data.name = this.name;
            this.data.id_official=this.official
          
            this.valid = false,
                axios
                    .post("/grade-store", this.data)
                    .then(() => {
                        this.displayNotification(
                            "success",
                            "Éxito",
                            "Curso registrado"
                        );
                        setTimeout(() => this.$router.push({ name: "grade" }), 3000);
                    })
                    .catch((err) => {
                        console.log(err, "error");
                        this.displayNotification(
                            "error",
                            "Error",
                            "Error al crear el curso"
                        );
                    });
        },



    },
};
</script>