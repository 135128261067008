<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">


            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Listado de Alumnos</span>
              </v-col>
              
              <v-btn to="/upload-student" color="mr-2">
                <v-icon left> mdi-account-arrow-up </v-icon>
                Carga Masiva
              </v-btn>

              <v-btn to="/create-student" color=" mr-2">
                <v-icon left> mdi-account-multiple-plus </v-icon>
                Registrar Alumno
              </v-btn>
            </v-row>
          </v-toolbar>

          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="5" class="ml-10">
              <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
            </v-col>
          </v-row>
          <v-card-text>
            <v-data-table
              id="my-table"
              :headers="headers"
              :items="students"
              :loading="loading"
              loading-text="Cargando... Por favor espere"
              :search="search"
              :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }"
              no-data-text="No hay datos disponibles"
              no-results-text="No hay datos disponibles"
            >
              <template v-slot:[`item.region_name`]="{ item }">
                <span>{{ getRegionById(item.region_id) }}</span>
              </template>

              <template v-slot:[`item.commune_name`]="{ item }">
                <span>{{ getCommuneById(item.commune_id) }}</span>
              </template>

              <template v-slot:[`item.grade_id`]="{ item }">
                <span>{{ getGradeById(item.grade_id) }}</span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon :to="'/update-student/' + item.id">
                      <v-icon v-bind="attrs" color="primary" v-on="on">
                        mdi-pencil-box-multiple
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Editar</span>
                </v-tooltip>

                <v-dialog transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                      <v-card-text>
                        <div class="text-h7 pa-6">
                          ¿Estás seguro que deseas eliminar el alumno?
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          class="primary"
                          text
                          @click="
                            dialog.value = false;
                            deleteItem(item);
                          "
                          >Aceptar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import jsPDF from "jspdf";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  data() {
    return {
      drawer: true,
      dialog: false,
      loading:true,
      search: "",
      headers: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Apellido Paterno", value: "last_name" },
        { text: "Apellido Materno", value: "mother_last_name" },
        { text: "Región", value: "region_name" },
        { text: "Comuna", value: "commune_name" },
        { text: "Dirección", value: "address" },
        { text: "Curso", value: "grade_id" },
        { text: "Acciones", value: "actions" },
      ],
      students: [],
      grades: [],
      regions: [],
      communes: [],
    };
  },

  mounted() {
    this.action = "Eliminar";
    this.getRegions();
    this.getCommunes();
    this.getGrades();
    this.getStudents();
  },

  methods: {
    getRegionById(region_id) {
      let region = this.regions.find((r) => r.id === region_id);
      if (region == null || region == "") return "No asignada";
      return region.name;
    },

    getCommuneById(commune_id) {
      let commune = this.communes.find((c) => c.id === commune_id);
      if (commune == null || commune == "") return "No asignada";
      return commune.name;
    },

    getGradeById(grade_id) {
      let grade = this.grades.find((c) => c.id === grade_id);
      if (grade == null || grade == "") return "No asignado";
      return grade.name;
    },
    getStudents() {
      axios
        .get("/student-index")
        .then((response) => {
          this.students = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estudiantes"
          );
        }).finally(()=>{
          this.loading =false;
        });
    },
    generatePDF() {
      const doc = new jsPDF();
      doc.html(document.querySelector("#my-table"), {
        callback: function (pdf) {
          pdf.save("table.pdf");
        },
      });
    },

    /*   getGrades()
       {
           axios
       .get("/student-index")
       .then((response) => {  
         this.students = response.data;         
       })
       .catch((err) => {
         console.log(err, "error");
         this.displayNotification(
           "error",
           "Error",
           "Error al obtener los estudiantes"
         );
       });
       },
*/
    getRegions() {
      axios
        .get("http://localhost:8000/api/region-index")
        .then((response) => {
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    getCommunes() {
      axios
        .get("http://localhost:8000/api/commune-index")
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    getGrades() {
      axios
        .get("http://localhost:8000/api/grade-index")
        .then((response) => {
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    deleteItem(item) {
      let request = {
        id: item.id,
      };
      axios
        .post("/student-destroy", request)
        .then(() => {
          this.displayNotification("success", "Éxito", "Estudiante Eliminado");
          this.getStudents();
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el estudiante"
          );
        });
    },
  },
};
</script>
