<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Modificar Permiso</span>
                </v-col>
              </v-row>
            </v-toolbar>

            <v-row class="mt-4 ml-4 mr-4">
              <v-col cols="6">
                <v-text-field
                  prepend-icon="mdi-key-chain-variant"
                  v-model="name"
                  :rules="nameRules"
                  label="Nombre del Permiso *"
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="6">
                <v-text-field
                  prepend-icon="mdi-view-module"
                  v-model="module"
                  :rules="moduleRules"
                  label="Nombre del Módulo *"
                  type="text"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row class="mt-4 ml-4 mr-4">
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-eye"
                  v-model="description"
                  label="Observaciones"
                  type="text"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/permission" text> Cancelar </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="update()">
                  Modificar
                </v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      name: "",
      data: {},
      valid: true,
      module: "",
      description: "",

      nameRules: [
        (v) => !!v || "El nombre del Permiso es requerido",
        (v) =>
          (v && v.length <= 34) ||
          "El nombre del Permiso debe tener menos de 40 caracteres",
      ],
    };
  },

  mounted() {
    this.chargeData();
  },

  methods: {
    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/permission-show", request)
        .then((response) => {
          console.log(response.data);
          this.name = response.data.name;
          this.module = response.data.module;
          this.description = response.data.description;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar el permiso"
          );
        });
    },

    update() {
      console.log("cargando datos");
      this.data.name = this.name;
      this.data.id = this.$route.params.id;
      this.data.module = this.module;
      this.data.description = this.description;
      (this.valid = false),
        axios
          .post("/permission-update", this.data)
          .then(() => {
            this.displayNotification("success", "Éxito", "Permiso modificado");
            setTimeout(() => this.$router.push({ name: "permission" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Error al modificar el Permiso"
            );
          });
    },
  },
};
</script>
