<template>
    <div>
        <v-row>
          <vue-snotify></vue-snotify>
            <v-col cols="12" md="12">
                <v-card class="mt-2 mb-4 ">

                    <v-toolbar color="primary">
                        <v-row align="center">
                            <v-col class="grow ">

                                <span style="color: white;"> Listado de Comunas </span>
                            </v-col>
                         
                            <v-btn to="/create-commune" color=" mr-2">
                                <v-icon left>
                                    mdi-map-marker
                                </v-icon>
                                Registrar Comuna
                            </v-btn>

                        </v-row>
                    </v-toolbar>

                    <v-card-text>          


                        <v-data-table :headers="headers" :items="communes" :search="search" :footer-props="{
                            itemsPerPageText: 'Filas por página',
                            itemsPerPageAllText: 'todos',
                        }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
                       
                       <template v-slot:[`item.region_name`]="{ item }">                            
                            <span>{{ getRegionById(item.region_id)}}</span>
                           </template>
           <template v-slot:[`item.actions`]="{ item }">   
             
            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }"> 
                <v-btn icon :to="'/update-commune/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>

              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:[`activator`]="{ on, attrs }"> 
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">      
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar la comuna?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      
                      @click="
                       dialog.value = false;
                        deleteItem(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
            

                        </v-data-table>
                    
                    </v-card-text>

                </v-card>
            </v-col>

            
        </v-row>

    </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
    mixins: [SnotifyMixin],
    VueSnotify,

    data() {
        return {
         
            drawer: true,
            dialog: false,
            search: "",
            region:"",
            cont:0,
            headers: [               
                
                { text: "Región", value: "region_name" },
                { text: "Comuna", value: "name" },
                { text: "Acciones", value: "actions" },               
            ],

            communes: [], 
            regions:[],         

        }
    },

    mounted() {
    this.action = "Eliminar" 
    this.getCommunes()
    this.getRegions()
  },

    methods: {  

    getRegionById(region_id) {
      let region = this.regions.find((c) => c.id === region_id);
      return region.name;
    },
      deleteItem(item) {       
    
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/commune-destroy", request)
        .then(() => {
          this.getCommunes()
          this.displayNotification("success", "Éxito", "Comuna eliminada");
        
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la comuna"
          );
        });
    },
    getCommunes()
        {
            axios
        .get("/commune-index")
        .then((response) => {  
          console.log(response.data)
          this.communes = response.data;         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
        },     
        getRegions()
        {
            axios
        .get("/region-index")
        .then((response) => {  
          console.log(response.data)
          this.regions = response.data;         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
        },   
    },
};
</script>