<template>
  <v-main>
    <vue-snotify></vue-snotify>
    <v-container fluid fill-height>

      <v-layout align-center justify-center>
        <v-row>
          <v-row  v-if="visible">

          <v-col cols="12" md="1" sm="0" xs="0"></v-col>
          <v-col cols="12" md="10" sm="12" xs="12">
            <v-alert text prominent type="error" icon="mdi-timer-lock" elevation="2" color="red" border="left">

              <v-row align="center">
                <v-col cols="12" md="10" sm="12" xs="12"> <strong>
                    ¡Atención! El período de uso de Luupa ha expirado.</strong><br>
                  Para seguir disfrutando de sus beneficios, por favor, póngase en
                  contacto con nuestro equipo de soporte lo antes posible.</v-col>
                <v-col class="shrink">
                  <v-btn color="error">Contactar</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="1" sm="0" xs="0"></v-col>

          </v-row>
          <v-container fluid fill-height>
          <v-row>
          <v-col cols="12" md="4" sm="2" xs="2"></v-col>
          <v-col cols="12" md="4" sm="8" xs="8" class="pr-4 pl-4">

            <v-card class="elevation-3 rounded-xl">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="primary">
              </v-toolbar>

              <v-card class="rounded-xl" style="z-index:2;margin-top: -20px;">
                <v-card-text>
                  <div class="pt-5"></div>
                  <v-img src="../assets/logo.png" class="justify-center" max-height="150" max-width="220" style="display:block;
margin:auto;">
                  </v-img>
                  <div class="pt-5"></div>
                  <div class="pt-5"></div>
                  <div class="pt-8"></div>
                  <v-form pt-4 v-model="valid">
                    <v-text-field prepend-icon="mdi-account" v-model="email" :rules="emailRules" label="Usuario"
                      type="text" :ref="nombreRef"></v-text-field>
                    <v-text-field prepend-icon="mdi-lastpass" v-model="password" :rules="passwordRules" label="Contraseña"
                      type="password"></v-text-field>
                    <div style="text-align: center;" class="pt-5">
                      <v-btn depressed :disabled="!valid"
                      :loading="loading"
     
       @click="login" color="primary" style="margin: auto;">
                        <v-icon left dark>
                          mdi-location-enter
                        </v-icon>
                        Ingresar </v-btn>
                    </div>


                  </v-form>
                </v-card-text>
                <div class="pt-5"></div>
                <div class="pt-5"></div>
                <div class="pt-5"></div>
              </v-card>

              <v-toolbar dark color="primary" style="margin-top: -20px; height: 70px;">

                <p style="margin:auto;" class="pt-6">Plataforma AMD</p>
              </v-toolbar>
            </v-card>

          </v-col>
          <v-col cols="12" md="4" sm="2" xs="2"></v-col>
        </v-row>
          </v-container>
        </v-row>

      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import { useUserStore } from "@/store/user";

export default {
  name: 'LoginView',
  mixins: [SnotifyMixin],
  VueSnotify,
  data() {
    return {
      institutions: [],
      visible: false,
      permissions: [],
      nombreRef: '',
      valid: true,
      loading:false,
      email: '',
      emailRules: [
        v => !!v || 'El correo es requerido',
        v => /.+@.+\..+/.test(v) || 'El correo no es válido',
      ],
      password: '',
      passwordRules: [
        v => !!v || 'La contraseña es requerida',

      ],
      btn_state: true,
      state: (this.email != "" && this.password != "") ? false : true,

    }
  },
  setup() {
    const store = useUserStore();
    return { store };
  },

  mounted() {
    this.closed();
    this.getInstitutions();
    this.$refs[this.nombreRef].focus()
  },

  methods: {

    getInstitutions() {
      axios
        .get("/institution-index")
        .then((response) => {
         
          console.log(response.data);
          this.institutions = response.data;

           const actualDate = new Date();
           const expire = new Date(this.institutions[0].end_date);

           if(expire<=actualDate)
           {

            this.visible =true;
                     }
          
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar datos de la institución"
          );
        });
    },

    getPermissions() {
      let request = {
        token: this.store.token,
      };

    
      axios
        .post("/show-user-by-token", request)
        .then((response) => {
          console.log("Estos son los permisos");
          console.log(response.data.permissions);

          this.store.setPermissions(response.data.permissions);
          this.store.setRole(response.data.role);

          this.permissions = response.data.permissions;
          console.log("Permisos " + this.permissions);
          console.log("Permisos " + this.permissions[0]);
          if (this.permissions.length == 0) {
            this.displayNotification(
              "error",
              "Error",
              "Este usuario no posee permisos en la Aplicación "
            );

          } else {

           this.$router.push("/home");

      
          }

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        }).finally(()=>
        {
        
        })

    },
    validate() {
      this.$refs.form.validate()
    },

    login() {
      this.loading=true
      axios
        .post("/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          // Login exitoso, guardar el token en localStorage
          const token = response.data.token;
         
          this.store.setToken(token);
         // this.store.setRole(response.data.role);

      
         this.getPermissions()
          // this.storePermissions.setPermission(response.data.user.roles.permissions);

        })
        .catch((error) => {
          console.log(error);
          this.displayNotification(
            "error",
            "Error",
            "Credenciales incorrectas"
          )
           
        }) .finally(() => {
          this.loading=false
            });
    },

    closed() {
      localStorage.removeItem('role');
      localStorage.removeItem('permissions');
      localStorage.removeItem('token');

      this.store.logout();
       
    }, 
 
  }
}




</script>
<style  scoped>
#bar-login {
  height: 30px;
}

.form-container {
  margin-top: 10px;
  padding: 15px 30px;
}

.name6tY4bPYk {
  display: none !important;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.m-20 {
  margin-top: 20px;
}

.m-t-30 {
  margin-top: 30px;
}

.btn-login {
  width: 100%;
  padding: 25px !important;
}

.btc-spoc-login {
  font-size: 18px;
  margin-top: 40px;
  width: 85%;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.labelForm {
  font-size: 16px;
  color: #3f51b5;
}

.recover-pass-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.captchaRequerid {
  margin-top: 10px;
}

.text-danger {
  font-size: 14px !important;
}
</style>
