<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Crear Alumno</span>
              </v-col>
            </v-row>
          </v-toolbar>

          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Datos del Alumno
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">
                Datos del Apoderado Titular
              </v-stepper-step>
              <v-divider></v-divider>
 <v-stepper-step step="3">
                Datos del Apoderado Secundario
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row class="mb-3">
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-card-account-details"
                        :counter="10"
                        v-model="rut"
                        :rules="rutRules"
                        v-mask="'########-X'"
                        placeholder="12345678-9"
                        label="RUT *"
                        type="text"
                      ></v-text-field 
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-account"
                        v-model="name"
                        :rules="nameRules"
                        label="Nombre *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-alpha-p-box"
                        v-model="last_name"
                        :rules="last_name_Rules"
                        label="Apellido Paterno *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-alpha-m-box"
                        v-model="mother_last_name"
                        :rules="mother_last_name_Rules"
                        label="Apellido Materno *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-gender-male-female"
                        v-model="sex"
                        :rules="sexRules"
                        label="Género *"
                        type="text"
                      ></v-text-field
                    ></v-col>

                      <v-col cols="12" md="4">
                      <template>
                        <v-container>
                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="mt-0 pt-0"
                                v-model="computedDateFormatted"
                                label="Fecha de Nacimiento *"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              no-title
                              @input="menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-container>
                      </template>
                   </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-cellphone"
                        v-model="mobile"
                        :rules="mobileRules"
                        v-mask="'########'"
                        :value="currentValue" 
                        @input="handleInput"
                        prefix="+569"

                        label="Teléfono 1 *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="region"
                        no-data-text="No hay datos disponibles"
                        :rules="regionRules"
                        :items="regions"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-map"
                        :disabled="itemDisabled"
                        label="Región *"
                        @change="getCommunes()"
                        persistent-hint
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="commune"
                        no-data-text="No hay datos disponibles"
                        :rules="communeRules"
                        :items="communes"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-account-details"
                        :disabled="itemDisabled"
                        label="Comuna *"
                        persistent-hint
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-map-marker"
                        v-model="address"
                        :rules="addressRules"
                        label="Dirección *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-email-fast"
                        v-model="email"
                        :rules="emailRules"
                        label="Correo Electrónico *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="grade"
                        no-data-text="No hay datos disponibles"
                        :rules="gradeRules"
                        :items="grades"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-account-details"
                        :disabled="itemDisabled"
                        label="Curso *"
                        persistent-hint
                      >
                      </v-select>
                    </v-col>

          
                    <v-col cols="12" md="8">
                      <v-text-field
                        prepend-icon="mdi-eye"
                        v-model="observations"
                        label="Observaciones"
                        type="text"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </v-form>
                <v-divider class="mb-3"></v-divider>
                <v-row>
                  <v-col cols="12" md="9"></v-col>
                  <v-col cols="12" md="3">
                    <v-btn to="/student" text> Cancelar </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="!valid"
                      @click="validateFormStudent()"
                    >
                      Continuar
                    </v-btn></v-col
                  >
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form ref="form2" v-model="valid2" lazy-validation>
                  <v-row class="mb-3">
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-card-account-details"
                        :counter="10"
                        v-model="ap_rut"
                        :rules="ap_rutRules"
                        placeholder="12345678-9"
                        label="RUT *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-account"
                        v-model="ap_name"
                        :rules="ap_nameRules"
                        label="Nombre *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-alpha-p-box"
                        v-model="ap_last_name"
                        :rules="ap_last_name_Rules"
                        label="Apellido Paterno *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-alpha-m-box"
                        v-model="ap_mother_last_name"
                        :rules="ap_mother_last_name_Rules"
                        label="Apellido Materno *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-alpha-m-box"
                        v-model="ap_relationship"
                        :rules="ap_relationshipRules"
                        label="Relación con el Estudiante *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-email-fast"
                        v-model="ap_email"
                        :rules="ap_emailRules"
                        label="Correo Electrónico *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-cellphone"
                        v-model="ap_mobile"
                        :rules="ap_mobileRules"
                        label="Teléfono *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="ap_region"
                        no-data-text="No hay datos disponibles"
                        :rules="regionRules"
                        :items="regions"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-map"
                        :disabled="itemDisabled"
                        label="Región *"
                        @change="getCommunesP()"
                        persistent-hint
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="ap_commune"
                        no-data-text="No hay datos disponibles"
                        :rules="communeRules"
                        :items="communes"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-account-details"
                        :disabled="itemDisabled"
                        label="Comuna *"
                        persistent-hint
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3"></v-divider>
                  <v-row>
                    <v-col cols="12" md="7"></v-col>
                    <v-col cols="12" md="5">
                      <v-btn to="/student" text> Cancelar </v-btn>
                      <v-btn class="mr-2" text @click="e1 = 1"> Volver </v-btn>
                      <v-btn
                        color="primary"
                        :disabled="!valid2"
                        @click="validateFormAP()"
                      >
                        Continuar
                      </v-btn></v-col
                    >
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-form ref="form3" v-model="valid3" lazy-validation>
                  <v-row class="mb-3">
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-card-account-details"
                        :counter="10"
                        v-model="as_rut"
                        :rules="as_rutRules"
                        placeholder="12345678-9"
                        label="RUT *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-account"
                        v-model="as_name"
                        :rules="as_nameRules"
                        label="Nombre *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-alpha-p-box"
                        v-model="as_last_name"
                        :rules="as_last_name_Rules"
                        label="Apellido Paterno *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-alpha-m-box"
                        v-model="as_mother_last_name"
                        :rules="as_mother_last_name_Rules"
                        label="Apellido Materno *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-alpha-m-box"
                        v-model="as_relationship"
                        :rules="as_relationshipRules"
                        label="Relación con el Estudiante *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-email-fast"
                        v-model="as_email"
                        :rules="as_emailRules"
                        label="Correo Electrónico *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-cellphone"
                        v-model="as_mobile"
                        :rules="as_mobileRules"
                        label="Teléfono *"
                        type="text"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="as_region"
                        no-data-text="No hay datos disponibles"
                        :rules="regionRules"
                        :items="regions"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-map"
                        :disabled="itemDisabled"
                        label="Región *"
                        @change="getCommunesS()"
                        persistent-hint
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="as_commune"
                        no-data-text="No hay datos disponibles"
                        :rules="communeRules"
                        :items="communes"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-account-details"
                        :disabled="itemDisabled"
                        label="Comuna *"
                        persistent-hint
                      >
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-divider class="mb-3"></v-divider>
                  <v-row>
                    <v-col cols="12" md="8"></v-col>
                    <v-col cols="12" md="4">
                      <v-btn to="/student" text> Cancelar </v-btn>
                      <v-btn class="mr-2" text @click="e1 = 1"> Volver </v-btn>
                      <v-btn
                        color="primary"
                        :disabled="!valid3"
                        @click="validateFormAS()"
                      >
                        Aceptar
                      </v-btn></v-col
                    >
                  </v-row>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      e1: 1,
      activePicker: null,
      menu: false,
      date: null,
      menu1: false,
     
      valid: true,
      valid2: true,
      valid3: true,
      data: {},

         date_birth_date: '',
         date_birth_date_formated: '',

     // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
   

      //Datos del Estudiante
      rut: "",
      name: "",
      last_name: "",
      mother_last_name: "",
      birth_date: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      sex: "",
      email: "",
      mobile: "",
      grade: "",
      age: "",
      region: "",
      commune: "",
      observations: "",
      address: "",

      //Datos del Apoderado Titular
      ap_rut: "",
      ap_name: "",
      ap_last_name: "",
      ap_mother_last_name: "",
      ap_relationship: "",
      ap_email: "",
      ap_mobile: "",
      ap_commune: "",
      ap_region: "",

      //Datos del Apoderado Secundario
      as_rut: "",
      as_name: "",
      as_last_name: "",
      as_mother_last_name: "",
      as_relationship: "",
      as_email: "",
      as_mobile: "",
      as_commune: "",
      as_region: "",

      functions: [],
      regions: [],
      communes: [],
      grades: [],

      //Reglas del Estudiante

      rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) =>(v && v.length <= 10) || "El RUT debe tener menos de 11 caracteres",       
        (v) => this.validaRut(v) || 'El RUT no es válido'],
        

      nameRules: [
        (v) => !!v || "El Nombre es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre debe tener menos de 41 caracteres",
      ],

      last_name_Rules: [
        (v) => !!v || "El Apellido Paterno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Paterno debe tener menos de 31 caracteres",
      ],

      mother_last_name_Rules: [
        (v) => !!v || "El Apellido Materno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Materno debe tener menos de 31 caracteres",
      ],

      birth_date_Rules: [(v) => !!v || "La Fecha de Nacimiento es requerida"],

      regionRules: [(v) => !!v || "La Región es requerida"],
      communeRules: [(v) => !!v || "La Comuna es requerida"],
      sexRules: [(v) => !!v || "El Género es requerido"],

      gradeRules: [(v) => !!v || "El Curso es requerido"],

      ageRules: [(v) => !!v || "La Edad es requerida"],
      mobileRules: [(v) => !!v || "El Teléfono es requerido"],

      addressRules: [
        (v) => !!v || "La Dirección es requerida",
        (v) =>
          (v && v.length <= 200) ||
          "La Dirección debe tener menos de 201 caracteres",
      ],

      emailRules: [
        (v) => !!v || "El Correo Electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico no es válido",
      ],

      //Reglas del Apoderado Titular

      ap_rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) =>
          (v && v.length <= 10) || "El RUT debe tener menos de 11 caracteres",
      ],

      ap_nameRules: [
        (v) => !!v || "El Nombre es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre debe tener menos de 41 caracteres",
      ],

      ap_last_name_Rules: [
        (v) => !!v || "El Apellido Paterno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Paterno debe tener menos de 31 caracteres",
      ],

      ap_mother_last_name_Rules: [
        (v) => !!v || "El Apellido Materno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Materno debe tener menos de 31 caracteres",
      ],

      ap_relationshipRules: [
        (v) => !!v || "La Relación con el estudiante es requerida",
      ],
      ap_regionRules: [(v) => !!v || "La Región es requerida"],
      ap_communeRules: [(v) => !!v || "La Comuna es requerida"],

      ap_emailRules: [
        (v) => !!v || "El Correo Electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico no es válido",
      ],

      ap_mobileRules: [(v) => !!v || "El Teléfono es requerido"],

      as_rutRules: [
       
        (v) =>
          (v && v.length <= 10) || "El RUT debe tener menos de 11 caracteres",
      ],

      as_nameRules: [
      
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre debe tener menos de 41 caracteres",
      ],

      as_last_name_Rules: [
        
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Paterno debe tener menos de 31 caracteres",
      ],

      as_mother_last_name_Rules: [
        
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Materno debe tener menos de 31 caracteres",
      ],

      as_relationshipRules: [
        
      ],
      as_emailRules: [
       
        (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico no es válido",
      ],

     
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },

    date () {
        this.dateFormatted = this.formatDate(this.date)
      },
  },

  computed: {    
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },

 
  mounted() {
    this.getRegions();
    this.getCommunes();
    this.getGrades();
  },
  methods: {

    validaRut(rutCompleto) {
		if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			return false;
		var tmp 	= rutCompleto.split('-');
		var digv	= tmp[1]; 
		var rut 	= tmp[0];
		if ( digv == 'K' ) digv = 'k' ;
		return (this.dv(rut) == digv );
	},
	dv(T){
		var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';
	},

    parseDate (date) {
        if (!date) return null

        const [day,month,year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

    save(date) {
      this.$refs.menu.save(date);
    },
    getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    getCommunes() {
      this.data.region_id = this.region;

      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },
    getCommunesS() {
      this.data.region_id = this.as_region;

      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },
    getCommunesP() {
      this.data.region_id = this.ap_region;

      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },
    getGrades() {
      axios
        .get("/grade-index")
        .then((response) => {
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    validateFormStudent() {
      this.$refs.form.validate();
      if (this.valid) {
        this.data.rut = this.rut;
        this.data.name = this.name;
        this.data.last_name = this.last_name;
        this.data.mother_last_name = this.mother_last_name;
        this.data.birth_date = this.birth_date;
        this.data.sex = this.sex;
        this.data.email = this.email;
        this.data.mobile = this.mobile;
        this.data.grade = this.grade;
       // this.data.age = this.age;
        this.data.region = this.region;
        this.data.commune = this.commune;
        this.data.observations = this.observations;
        this.data.address = this.address;

        this.e1 = 2;
      }
    },

    validateFormAP() {
      this.$refs.form2.validate();
      if (this.valid2) {
        this.data.ap_rut = this.ap_rut;
        this.data.ap_name = this.ap_name;
        this.data.ap_last_name = this.ap_last_name;
        this.data.ap_mother_last_name = this.ap_mother_last_name;
        this.data.ap_relationship = this.ap_relationship;
        this.data.ap_email = this.ap_email;
        this.data.ap_mobile = this.ap_mobile;
        this.data.ap_region_id = this.ap_region;
        this.data.ap_commune_id = this.ap_commune;

        this.e1 = 3;
      }
    },

    validateFormAS() {
      this.$refs.form2.validate();
      if (this.valid3) {
        this.data.as_rut = this.as_rut;
        this.data.as_name = this.as_name;
        this.data.as_last_name = this.as_last_name;
        this.data.as_mother_last_name = this.as_mother_last_name;
        this.data.as_relationship = this.as_relationship;
        this.data.as_email = this.as_email;
        this.data.as_mobile = this.as_mobile;
        this.data.as_region_id = this.as_region;
        this.data.as_commune_id = this.as_commune;
        axios
          .post("/student-store", this.data)
          .then(() => {
            this.displayNotification(
              "success",
              "Éxito",
              "Alumno creado correctamente"
            );
            setTimeout(() => this.$router.push({ name: "student" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Ha ocurrido un error al registrar el estudiante"
            );
          });
      }
    },
  },
};
</script>
