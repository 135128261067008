<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow ">

                  <span style="color: white;"> Registrar Funcionario</span>
                </v-col>

              </v-row>
            </v-toolbar>

            <v-card-text>
              <v-row class="mt-4 ml-4 mr-4">
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-card-account-details" :counter="10"
                    v-model="rut" :rules="rutRules" placeholder="12345678-9" label="RUT *"
                    type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-account" v-model="name" :rules="nameRules"
                    label="Nombre *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-alpha-p-box" v-model="last_name"
                    :rules="last_name_Rules" label="Apellido Paterno *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-alpha-m-box" v-model="mother_last_name"
                    :rules="mother_last_name_Rules" label="Apellido Materno *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-gender-male-female" v-model="sex"
                    :rules="sexRules" label="Género *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <template>
                    <div>

                      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="birth_date" :rules="birth_date_Rules" label="Fecha de Nacimiento *"
                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="birth_date" locale="es-cl" :active-picker.sync="activePicker"
                          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                          min="1950-01-01" @change="save"></v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                </v-col>

                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-cellphone" v-model="mobile"
                    :rules="mobileRules" label="Teléfono *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="region" no-data-text="No hay datos disponibles" :rules="regionRules"
                    :items="regions" item-text="name" item-value="id" prepend-icon="mdi-map" :disabled="itemDisabled"
                    label="Región *" @change="getCommunes()" persistent-hint>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="commune" no-data-text="No hay datos disponibles" :rules="communeRules"
                    :items="communes" item-text="name" item-value="id" prepend-icon="mdi-account-details"
                    :disabled="itemDisabled" label="Comuna *" persistent-hint>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-map-marker" v-model="address"
                    :rules="addressRules" label="Dirección *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-email-fast" v-model="email" :rules="emailRules"
                    label="Correo Electrónico *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-calendar-account-outline
" v-model="contractHours" :rules="contractHoursRules" label="Cantidad de Horas de Contrato *"
                    type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="id_functionOfficial" no-data-text="No hay datos disponibles"
                    :rules="functionOfficialRules" :items="functions" item-text="name" item-value="id"
                    prepend-icon="mdi-account-details" :disabled="itemDisabled" label="Función *" persistent-hint>
                  </v-select>
                </v-col>




              </v-row>

            </v-card-text>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/official" class="mr-2" text>
                  Cancelar
                </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="create()">
                  Aceptar
                </v-btn></v-col>
            </v-row>
          </v-form>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {

      data: {},
      valid: true,

      //Datos del Funcionario

      rut: "",
      name: "",
      last_name: "",
      mother_last_name: "",
      birth_date: null,
      sex: "",
      email: "",
      mobile: "",
      contractHours: 0,
      region: "",
      commune: "",
      functionOfficial: "",
      id_functionOfficial: 0,
      address: "",

      functions: [],
      regions: [],
      communes: [],


      functionOfficialRules: [
        v => !!v || 'La Función es requerida',
      ],
      rutRules: [
        v => !!v || 'El RUT es requerido',
        v => (v && v.length <= 10) || 'El RUT debe tener menos de 11 caracteres',
      ],

      nameRules: [
        v => !!v || 'El Nombre es requerido',
        v => (v && v.length <= 40) || 'El Nombre debe tener menos de 41 caracteres',
      ],

      last_name_Rules: [
        v => !!v || 'El Apellido Paterno es requerido',
        v => (v && v.length <= 30) || 'El Apellido Paterno debe tener menos de 31 caracteres',
      ],

      mother_last_name_Rules: [
        v => !!v || 'El Apellido Materno es requerido',
        v => (v && v.length <= 30) || 'El Apellido Materno debe tener menos de 31 caracteres',
      ],

      birth_date_Rules: [
        v => !!v || 'La Fecha de Nacimiento es requerida',
      ],

      regionRules: [
        v => !!v || 'La Región es requerida',
      ],
      communeRules: [
        v => !!v || 'La Comuna es requerida',
      ],
      sexRules: [
        v => !!v || 'El Género es requerido',
      ],

      contractHoursRules: [
        v => !!v || 'Las Horas de contrato son requeridas',
      ],
      mobileRules: [
        v => !!v || 'El Teléfono es requerido',
      ],

      addressRules: [
        v => !!v || 'La Dirección es requerida',
        v => (v && v.length <= 200) || 'La Dirección debe tener menos de 201 caracteres',
      ],

      emailRules: [
        v => !!v || 'El Correo Electrónico es requerido',
        v => /.+@.+\..+/.test(v) || 'El Correo Electrónico no es válido',
      ],



    }
  },
  mounted() {
    this.action = "Eliminar"
    this.getFunctions()
    this.getRegions()
    this.getCommunes()

  },


  methods: {

    getFunctions() {
      axios
        .get("/functionOfficial-index")
        .then((response) => {
          this.functions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las funciones"
          );
        });
    },

    getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    getCommunes() {
     
      this.data.region_id = this.region;
      
      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },


    create() {
      this.data.name = this.name;
      this.valid = false,
        this.data.rut = this.rut;
      this.data.name = this.name;
      this.data.last_name = this.last_name;
      this.data.mother_last_name = this.mother_last_name;
      this.data.numbers_hours_contract = this.contractHours;
      this.data.email = this.email;
      this.data.mobile = this.mobile;
      this.data.direction = this.address;
      this.data.region_id = this.region;
      this.data.commune_id = this.commune;
      this.data.function_official_id = this.id_functionOfficial;
      this.data.birth_date = this.birth_date;
      this.data.sex = this.sex;

      axios
        .post("/official-store", this.data)
        .then(() => {
          this.displayNotification(
            "success",
            "Éxito",
            "Funcionario registrado"
          );
          setTimeout(() => this.$router.push({ name: "official" }), 3000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al crear el funcionario"
          );
        });
    },



  },
};
</script>