<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Modificar Institución</span>
                </v-col>
              </v-row>
            </v-toolbar>

            <v-card-text>
              <v-row class="mt-4 ml-4 mr-4">
                <v-col cols="12" md="4">
                  <v-file-input
                    v-validate="'required'"
                    ref="fileInput"
                    v-model="file"
                    name="file"
                    accept=".png, .jpg"
                    label="Seleccione el Logotipo"
                    @change="onFileSelected"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-card-account-details"
                    :counter="10"
                    v-model="rut"
                    :rules="rutRules"
                    placeholder="12345678-9"
                    label="RUT *"
                    type="text"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-account"
                    v-model="name"
                    :rules="nameRules"
                    label="Nombre *"
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-clock"
                    v-model="late_arrivals"
                    :rules="late_arrivalsRules"
                    label="Retrasos permitidos *"
                    type="text"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="address"
                    :rules="addressRules"
                    label="Dirección *"
                    type="text"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-cellphone"
                    v-model="mobile"
                    :rules="mobileRules"
                    label="Teléfono *"
                    type="text"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-email-fast"
                    v-model="email"
                    :rules="emailRules"
                    label="Correo Electrónico *"
                    type="text"
                  ></v-text-field
                ></v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-alpha-m-box"
                    v-model="manager_name"
                    :rules="manager_name_Rules"
                    label="Nombre del gerente *"
                    type="text"
                  ></v-text-field
                ></v-col>

                <v-col cols="12" md="4">
                  <template>
                    <div>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="start_date"
                            :rules="start_date_Rules"
                            label="Fecha Inicial*"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="start_date"
                          :active-picker.sync="activePicker"
                       
                          min="1950-01-01"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" md="4">
                  <template>
                    <div>
                      <v-menu
                        ref="menuf"
                        v-model="menuf"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="end_date"
                            :rules="end_date_Rules"
                            label="Fecha Final*"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="end_date"
                          :active-picker.sync="activePicker"
                        
                          :min="new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="region"
                    no-data-text="No hay datos disponibles"
                    :rules="regionRules"
                    :items="regions"
                    item-text="name"
                    item-value="id"
                    prepend-icon="mdi-map"
                    :disabled="itemDisabled"
                    label="Región *"
                    persistent-hint
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="commune"
                    no-data-text="No hay datos disponibles"
                    :rules="communeRules"
                    :items="communes"
                    item-text="name"
                    item-value="id"
                    prepend-icon="mdi-account-details"
                    :disabled="itemDisabled"
                    label="Comuna *"
                    persistent-hint
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="4">
                  <template>
                    <v-container fluid>
                      <v-checkbox
                        v-model="whatsapp_notification"
                        label="Notificación por Whatsapp"
                      ></v-checkbox>
                    </v-container>
                  </template>
                </v-col>
                <v-col cols="12" md="4">
                  <template>
                    <v-container fluid>
                      <v-checkbox
                        v-model="email_notification"
                        label="Notificación por Correo"
                      ></v-checkbox>
                    </v-container>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/institution" class="mr-2" text> Cancelar </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="update()">
                  Aceptar
                </v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      data: {},
      valid: true,
      //Datos del Funcionario
      rut: "",
      name: "",
      address: "",
      file: null,
      mobile: "",
      email: "",
      manager_name: "",
      start_date: null,
      end_date: null,
      region: "",
      commune: "",
      whatsapp_notification: false,
      email_notification: false,
      late_arrivals: "",
      regions: [],
      communes: [],

      rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) =>
          (v && v.length <= 10) || "El RUT debe tener menos de 11 caracteres",
      ],

      nameRules: [
        (v) => !!v || "El Nombre es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre debe tener menos de 41 caracteres",
      ],

      mobileRules: [(v) => !!v || "El Teléfono es requerido"],

      addressRules: [
        (v) => !!v || "La Dirección es requerida",
        (v) =>
          (v && v.length <= 200) ||
          "La Dirección debe tener menos de 201 caracteres",
      ],

      emailRules: [
        (v) => !!v || "El Correo Electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico no es válido",
      ],

      namanager_name_Rules: [
        (v) => !!v || "El nombre del gerente es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El nombre del gerente debe tener menos de 31 caracteres",
      ],


      regionRules: [(v) => !!v || "La Región es requerida"],
      communeRules: [(v) => !!v || "La Comuna es requerida"],
    };
  },
  mounted() {
    this.action = "Eliminar";
    this.chargeData();
    this.getRegions();
    this.getCommunes();
  },

  methods: {
    onFileSelected(event) {
      console.log("entra a carga imagen");
      this.file = event.target.files[0];
      console.log("carga imagen");
      console.log(this.file);
    },
    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };

      axios
        .post("/institution-show", request)
        .then((response) => {
          console.log("Respuesta");
          console.log(response);
          //const formData = new FormData();
          this.rut = response.data.rut;
          this.name = response.data.name;
          this.address = response.data.direction;
          this.late_arrivals = response.data.late_arrivals;
          this.mobile = response.data.phone;
          this.email = response.data.email;
          this.manager_name = response.data.manager_name;
          this.start_date = response.data.start_date;
          this.end_date = response.data.end_date;
          this.region = response.data.region_id;
          this.commune = response.data.commune_id;
          this.whatsapp_notification = response.data.whatsapp_notification;
          this.email_notification = response.data.email_notification;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error cargando datos del funcionario"
          );
        });
    },

    getFunctions() {
      axios
        .get("/functionOfficial-index")
        .then((response) => {
          this.functions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las funciones"
          );
        });
    },

    getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    getCommunes() {
      axios
        .get("/commune-index")
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    update() {
      (this.valid = false),
        (this.data.rut = this.rut),
        (this.data.name = this.name),
        (this.data.direction = this.address),
        (this.data.late_arrivals = this.late_arrivals),
        (this.data.phone = this.mobile),
        (this.data.email = this.email),
        (this.data.manager_name = this.manager_name),
        (this.data.start_date = this.start_date),
        (this.data.end_date = this.end_date),
        (this.data.region_id = this.region),
        (this.data.commune_id = this.commune),
        (this.data.whatsapp_notification = this.whatsapp_notification),
        (this.data.email_notification = this.email_notification);
      axios
        .post("/institution-update", this.data)
        .then(() => {
          this.displayNotification(
            "success",
            "Éxito",
            "Institución modificada"
          );
          setTimeout(() => this.$router.push({ name: "institution" }), 3000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al modificar datos de la institución"
          );
        });
    },
  },
};
</script>
