import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import DashboardView from '@/views/DashboardView.vue'
import AccessControlView from '@/views/access-control/AccessControlView.vue'
import StudentView from '@/views/students/StudentView.vue'
import CreateStudentView from '@/views/students/CreateStudentView.vue'
import UpdateStudentView from '@/views/students/UpdateStudentView.vue'
import CommuneView from '@/views/commune/CommuneView.vue'
import CreateCommuneView from '@/views/commune/CreateCommuneView.vue'
import UpdateCommuneView from '@/views/commune/UpdateCommuneView.vue'
import FunctionView from '@/views/functions/FunctionView.vue'
import CreateFunctionView from '@/views/functions/CreateFunctionView.vue'
import UpdateFunctionView from '@/views/functions/UpdateFunctionView.vue'
import OfficialView from '@/views/officials/OfficialView.vue'
import CreateOfficialView from '@/views/officials/CreateOfficialView.vue'
import UpdateOfficialView from '@/views/officials/UpdateOfficialView.vue'
import StatusView from '@/views/status/StatusView.vue'
import CreateStatusView from '@/views/status/CreateStatusView.vue'
import UpdateStatusView from '@/views/status/UpdateStatusView.vue'
import AccessView from '@/views/access/AccessView.vue'
import CreateAccessView from '@/views/access/CreateAccessView.vue'
import UpdateAccessView from '@/views/access/UpdateAccessView.vue'
import VisitView from '@/views/visits/VisitView.vue'
import CreateVisitView from '@/views/visits/CreateVisitView.vue'
import UpdateVisitView from '@/views/visits/UpdateVisitView.vue'
import ActionView from '@/views/actions/ActionView.vue'
import CreateActionView from '@/views/actions/CreateActionView.vue'
import UpdateActionView from '@/views/actions/UpdateActionView.vue'
import CredencialTypeView from '@/views/credencial-type/CredencialTypeView.vue'
import CreateCredencialTypeView from '@/views/credencial-type/CreateCredencialTypeView.vue'
import UpdateCredencialTypeView from '@/views/credencial-type/UpdateCredencialTypeView.vue'
import GateView from '@/views/gate/GateView.vue'
import CreateGateView from '@/views/gate/CreateGateView.vue'
import UpdateGateView from '@/views/gate/UpdateGateView.vue'
import GradeView from '@/views/grades/GradeView.vue'
import CreateGradeView from '@/views/grades/CreateGradeView.vue'
import UpdateGradeView from '@/views/grades/UpdateGradeView.vue'
import InstitutionView from '@/views/institution/InstitutionView.vue'
import CreateInstitutionView from '@/views/institution/CreateInstitutionView.vue'
import UpdateInstitutionView from '@/views/institution/UpdateInstitutionView.vue'
import RegionView from '@/views/region/RegionView.vue'
import CreateRegionView from '@/views/region/CreateRegionView.vue'
import UpdateRegionView from '@/views/region/UpdateRegionView.vue'
import UserTypeView from '@/views/user-type/UserTypeView.vue'
import CreateUserTypeView from '@/views/user-type/CreateUserTypeView.vue'
import UpdateUserTypeView from '@/views/user-type/UpdateUserTypeView.vue'
import CredencialView from '@/views/credencial/CredencialView.vue'
import CreateCredencialView from '@/views/credencial/CreateCredentialView.vue'
import UpdateCredencialView from '@/views/credencial/UpdateCredencialView.vue'
import ScheduleView from '@/views/schedule/ScheduleView.vue'
import CreateScheduleView from '@/views/schedule/CreateScheduleView'
import UpdateScheduleView from '@/views/schedule/UpdateScheduleView'
import ShowScheduleView from '@/views/schedule/ShowScheduleView'
import NotFound from '@/views/NotFound.vue'
import Forbidden from '@/views/Forbidden.vue'
import HomeView from '@/views/HomeView.vue'
import RoleView from '@/views/role/RoleView.vue'
import CreateRoleView from "@/views/role/CreateRoleView"
import UpdateRoleView from "@/views/role/UpdateRoleView"
import PermissionRoleView from "@/views/role/AddPermissionToRole"
import AddRoleToUserView from "@/views/role/AddRoleToUser"
import PermissionView from "@/views/permission/PermissionView.vue"
import CreatePermissionView from "@/views/permission/CreatePermissionView"
import UpdatePermissionView from "@/views/permission/UpdatePermissionView"
import CredentialDuplicatedView from "@/views/inform/CredentialDuplicatedView"
import { setActivePinia, createPinia } from 'pinia'

import InformGradeView from "@/views/inform/InformGradeView";
import InformByRutView from "@/views/inform/InformByRutView";
import InformStudentTardiesView from "@/views/inform/InformStudentTardiesView";
import InformStudentTardiesGradeView from "@/views/inform/InformStudentTardiesGradeView";

// eslint-disable-next-line
import { useUserStore } from "@/store/user"



Vue.use(VueRouter)

const routes = [
  { path: '*', component: NotFound },
  { path: '/forbidden', component: Forbidden }, 
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },

  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true, requiresPermissions: ['view-dashboard'] },
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView,
        meta: { requiresAuth: true, requiresPermissions: ['permission-dash'] },
      },
      {
        path: 'action',
        name: 'action',
        component: ActionView,
        meta: { requiresAuth: true, requiresPermissions: ['view-action'] },
      },
      {
        path: 'create-action',
        name: 'create-action',
        component: CreateActionView,
        meta: { requiresAuth: true, requiresPermissions: ['create-action'] },
      },
      {
        path: 'update-action/:id',
        name: 'update-action',
        component: UpdateActionView,
        meta: { requiresAuth: true, requiresPermissions: ['update-action'] },
      },
      {
        path: 'institution',
        name: 'institution',
        component: InstitutionView,
        meta: { requiresAuth: true, requiresPermissions: ['view-institution'] },
      },
      {
        path: 'create-institution',
        name: 'create-institution',
        component: CreateInstitutionView,
        meta: { requiresAuth: true, requiresPermissions: ['create-institution'] },
      },
      {
        path: 'update-institution/:id',
        name: 'update-institution',
        component: UpdateInstitutionView,
        meta: { requiresAuth: true, requiresPermissions: ['update-institution'] },
      },

      {
        path: 'gate',
        name: 'gate',
        component: GateView,
        meta: { requiresAuth: true, requiresPermissions: ['view-gate'] },
      },
      {
        path: 'create-gate',
        name: 'create-gate',
        component: CreateGateView,
        meta: { requiresAuth: true, requiresPermissions: ['create-gate'] },
      },
      {
        path: 'update-gate/:id',
        name: 'update-gate',
        component: UpdateGateView,
        meta: { requiresAuth: true, requiresPermissions: ['update-gate'] },
      },
      {
        path: 'grade',
        name: 'grade',
        component: GradeView,
        meta: { requiresAuth: true, requiresPermissions: ['view-grade'] },
      },
      {
        path: 'create-grade',
        name: 'create-grade',
        component: CreateGradeView,
        meta: { requiresAuth: true, requiresPermissions: ['create-grade'] },
      },
      {
        path: 'update-grade/:id',
        name: 'update-grade',
        component: UpdateGradeView,
        meta: { requiresAuth: true, requiresPermissions: ['update-grade'] },
      },
      {
        path: 'access-control',
        name: 'access-control',
        component: AccessControlView,
        meta: { requiresAuth: true, requiresPermissions: ['view-access-control'] },
      },
      {
        path: 'credential',
        name: 'credential',
        component: CredencialView,
        meta: { requiresAuth: true, requiresPermissions: ['view-credential'] },
      },
      {
        path: 'create-credential',
        name: 'create-credential',
        component: CreateCredencialView,
        meta: { requiresAuth: true, requiresPermissions: ['create-credential'] },
      },
      {
        path: 'update-credential/:id',
        name: 'update-credential',
        component: UpdateCredencialView,
        meta: { requiresAuth: true, requiresPermissions: ['update-credential'] },
      },

      {
        path: 'credential-type',
        name: 'credential-type',
        component: CredencialTypeView,
        meta: { requiresAuth: true, requiresPermissions: ['view-credential-type'] },
      },
      {
        path: 'create-credential-type',
        name: 'create-credential-type',
        component: CreateCredencialTypeView,
        meta: { requiresAuth: true, requiresPermissions: ['create-credential-type'] },
      },
      {
        path: 'update-credential-type/:id',
        name: 'update-credential-type',
        component: UpdateCredencialTypeView,
        meta: { requiresAuth: true, requiresPermissions: ['update-credential-type'] },
      },
      {
        path: 'user-type',
        name: 'user-type',
        component: UserTypeView,
        meta: { requiresAuth: true, requiresPermissions: ['view-user-type'] },
      },
      {
        path: 'create-user-type',
        name: 'create-user-type',
        component: CreateUserTypeView,
        meta: { requiresAuth: true, requiresPermissions: ['create-user-type'] },
      },
      {
        path: 'update-user-type/:id',
        name: 'update-user-type',
        component: UpdateUserTypeView,
        meta: { requiresAuth: true, requiresPermissions: ['update-user-type'] },
      },


      {
        path: 'student',
        name: 'student',
        component: StudentView,
        meta: { requiresAuth: true, requiresPermissions: ['view-student'] },
      },
      {
        path: 'create-student',
        name: 'create-student',
        component: CreateStudentView,
        meta: { requiresAuth: true, requiresPermissions: ['create-student'] },
      },
      {
        path: 'update-student/:id',
        name: 'update-student',
        component: UpdateStudentView,
        meta: { requiresAuth: true, requiresPermissions: ['update-student'] },
      },
      {
        path: 'commune',
        name: 'commune',
        component: CommuneView,
        meta: { requiresAuth: true, requiresPermissions: ['view-commune'] },
      },
      {
        path: 'create-commune',
        name: 'create-commune',
        component: CreateCommuneView,
        meta: { requiresAuth: true, requiresPermissions: ['create-commune'] },
      },
      {
        path: 'update-commune/:id',
        name: 'update-commune',
        component: UpdateCommuneView,
        meta: { requiresAuth: true, requiresPermissions: ['update-commune'] },
      },

      {
        path: 'function',
        name: 'function',
        component: FunctionView,
        meta: { requiresAuth: true, requiresPermissions: ['view-function'] },
      },
      {
        path: 'create-function',
        name: 'create-function',
        component: CreateFunctionView,
        meta: { requiresAuth: true, requiresPermissions: ['create-function'] },
      },
      {
        path: 'update-function/:id',
        name: 'update-function',
        component: UpdateFunctionView,
        meta: { requiresAuth: true, requiresPermissions: ['update-function'] },
      },
      {
        path: 'official',
        name: 'official',
        component: OfficialView,
        meta: { requiresAuth: true, requiresPermissions: ['view-official'] },
      },    
      {
        path: 'create-official',
        name: 'create-official',
        component: CreateOfficialView,
        meta: { requiresAuth: true, requiresPermissions: ['create-official'] },
      },
      {
        path: 'update-official/:id',
        name: 'update-official',
        component: UpdateOfficialView,
        meta: { requiresAuth: true, requiresPermissions: ['update-official'] },
      },

      {
        path: 'status',
        name: 'status',
        component: StatusView,
        meta: { requiresAuth: true, requiresPermissions: ['view-status'] },
      },
      {
        path: 'create-status',
        name: 'create-status',
        component: CreateStatusView,
        meta: { requiresAuth: true, requiresPermissions: ['create-status'] },
      },
      {
        path: 'update-status/:id',
        name: 'update-status',
        component: UpdateStatusView,
        meta: { requiresAuth: true, requiresPermissions: ['update-status'] },
      },
      {
        path: 'access',
        name: 'access',
        component: AccessView,
        meta: { requiresAuth: true, requiresPermissions: ['view-access'] },
      },
      {
        path: 'create-access',
        name: 'create-access',
        component: CreateAccessView,
        meta: { requiresAuth: true, requiresPermissions: ['create-access'] },
      },
      {
        path: 'update-access/:id',
        name: 'update-access',
        component: UpdateAccessView,
        meta: { requiresAuth: true, requiresPermissions: ['update-access'] },
      },
      {
        path: 'visit',
        name: 'visit',
        component: VisitView,
        meta: { requiresAuth: true, requiresPermissions: ['view-visit'] },
      },
      {
        path: 'create-visit',
        name: 'create-visit',
        component: CreateVisitView,
        meta: { requiresAuth: true, requiresPermissions: ['create-visit'] },
      },
      {
        path: 'update-visit/:id',
        name: 'update-visit',
        component: UpdateVisitView,
        meta: { requiresAuth: true, requiresPermissions: ['update-visit'] },
      },
      {
        path: 'region',
        name: 'region',
        component: RegionView,
        meta: { requiresAuth: true, requiresPermissions: ['view-region'] },
      },
      {
        path: 'create-region',
        name: 'create-region',
        component: CreateRegionView,
        meta: { requiresAuth: true, requiresPermissions: ['create-region'] },
      },
      {
        path: 'update-region/:id',
        name: 'update-region',
        component: UpdateRegionView,
        meta: { requiresAuth: true, requiresPermissions: ['update-region'] },
      },

      {
        path: 'schedule',
        name: 'schedule',
        component: ScheduleView,
        meta: { requiresAuth: true, requiresPermissions: ['view-schedule'] },
      },
      {
        path: 'create-schedule',
        name: 'create-schedule',
        component: CreateScheduleView,
        meta: { requiresAuth: true, requiresPermissions: ['create-schedule'] },
      },
      {
        path: 'update-schedule/:id',
        name: 'update-schedule',
        component: UpdateScheduleView,
        meta: { requiresAuth: true, requiresPermissions: ['update-schedule'] },
      },
      {
        path: 'show-calendar/:id',
        name: 'show-calendar',
        component: ShowScheduleView,
        meta: { requiresAuth: true, requiresPermissions: ['show-calendar'] },
      },


      {
        path: "role",
        name: "role",
        component: RoleView,
        meta: { requiresAuth: true, requiresPermissions: ['view-role'] },
      },
      {
        path: "create-role",
        name: "create-role",
        component: CreateRoleView,
        meta: { requiresAuth: true, requiresPermissions: ['create-role'] },
      },
      {
        path: "update-role/:id",
        name: "update-role",
        component: UpdateRoleView,
        meta: { requiresAuth: true, requiresPermissions: ['"update-role'] },
      },
      {
        path: "permission-role",
        name: "permission-role",
        component: PermissionRoleView,
        meta: { requiresAuth: true, requiresPermissions: ['view-permission-role'] },
      },
      {
        path: "role-user",
        name: "role-user",
        component: AddRoleToUserView,
        meta: { requiresAuth: true, requiresPermissions: ['view-role-user'] },
      },

      {
        path: "permission",
        name: "permission",
        component: PermissionView,
        meta: { requiresAuth: true, requiresPermissions: ['view-permission'] },
      },
      {
        path: "create-permission",
        name: "create-permission",
        component: CreatePermissionView,
        meta: { requiresAuth: true, requiresPermissions: ['create-permission'] },
      },
      {
        path: "update-permission/:id",
        name: "update-permission",
        component: UpdatePermissionView,
        meta: { requiresAuth: true, requiresPermissions: ['update-permission'] },
      },

      // TODO permisos de los informes que esta viendo ranses
      {
        path: "inform-duplicated-credendential",
        name: "inform-duplicated-credendential",
        component: CredentialDuplicatedView,
        meta: { requiresAuth: true, requiresPermissions: ['view-inform-duplicated-credendential'] },
      },

      {
        path: "/inform-grade",
        name: "inform-grade",
        component: InformGradeView,
        meta: { requiresAuth: true, requiresPermissions: ['view-inform-grade'] },
      },

      {
        path: "/inform-by-rut",
        name: "inform-by-rut",
        component: InformByRutView,
        meta: { requiresAuth: true, requiresPermissions: ['view-inform-by-rut'] },
      },
      {
        path: "/inform-student-tardies",
        name: "inform-student-tardies",
        component: InformStudentTardiesView,
        meta: { requiresAuth: true, requiresPermissions: ['view-inform-student-tardies'] },
      },
      {
        path: "/inform-student-tardies-grade",
        name: "inform-student-tardies-grade",
        component: InformStudentTardiesGradeView,
        meta: { requiresAuth: true, requiresPermissions: ['view-inform-student-tardies-grade'] },
        
      },



    ],
  }
]
/*
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
*/


const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
 setActivePinia(createPinia())
 /* const store = useUserStore()

  
 
  // Requiere autenticación
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log("Esta entrando aqui")
    // El usuario no está autenticado  

    const lastRouteName = localStorage.getItem('permissions')
    console.log(lastRouteName)
    console.log(
      to.matched.some(record => record.meta.requiresAuth));
      console.log(store.isAuthenticatedUser)
    if (!localStorage.getItem('isAuthenticated')) {
      console.log("aqui entra tambien")
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
      return
    }
  }
  // Requiere permisos
  if (to.matched.some(record => record.meta.requiresPermissions)) {
    const permissions = to.meta.requiresPermissions
    // El usuario no tiene los permisos requeridos

    const userPermissions = localStorage.getItem('permissions').split(",");

    const hasPermissions = permissions.every(permission =>
      userPermissions.includes(permission)
    );

    if(hasPermissions)
    {
      next()
    }
    else {
      next('/forbidden')
      return
    }
  }*/

  next()
})

export default router

