<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow ">
                  <span style="color: white;"> Crear Comuna</span>
                </v-col>

              </v-row>
            </v-toolbar>
            <v-row class="mt-4 ml-4">
              <v-col cols="12" md="4">
                <v-select v-model="region" no-data-text="No hay datos disponibles" :rules="regionRules" :items="regions"
                  item-text="name" item-value="id" prepend-icon="mdi-map" :disabled="itemDisabled" label="Nombre de la Región *"
                  persistent-hint>
                </v-select>
              </v-col>
              <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-map-marker" v-model="name" :rules="nameRules"
                  label="Nombre de la comuna *" type="text"></v-text-field></v-col>
              
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/commune" text>
                  Cancelar
                </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="create()">
                  Aceptar
                </v-btn></v-col>
            </v-row>
          </v-form>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      name: "",
      data: {},
      valid: true,
      region:"",
      regions:[],

      nameRules: [
        v => !!v || 'El nombre de la comuna es requerido',
        v => (v && v.length <= 19) || 'El nombre de la comuna debe tener menos de 20 caracteres',
      ],
      regionRules: [
        v => !!v || 'La Región es requerida',
      ],

    }
  },

  mounted() {
   
    this.getRegions() 

  },

  methods: {

    getRegions()
        {
            axios
        .get("/region-index")
        .then((response) => {  
          console.log(response.data)
          this.regions = response.data;         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
        },

    create() {
      this.data.name = this.name;
      this.data.region_id=this.region;
      this.valid = false,
        axios
          .post("/commune-store", this.data)
          .then(() => {
            this.displayNotification(
              "success",
              "Éxito",
              "Comuna registrada"
            );
            setTimeout(() => this.$router.push({ name: "commune" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Error al crear la comuna"
            );
          });
    },



  },
};
</script>