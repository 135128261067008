<template>
<v-app-bar height="60"  app >
    <v-row justify="space-around">

        <v-spacer></v-spacer>
    <v-menu    
      offset-y
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
        outlined
        color="primary"
          class="white--text ma-5"
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-door-open</v-icon><span class="ml-1">{{ store.name }}</span> 
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item"
          link
          @click="updateDoor(item)"
        >
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu    
      offset-y
    >
      <template v-slot:activator="{ attrs, on }">
     
        <v-btn
      class="mx-2 mt-4 mr-12"
      fab
      dark
      small
      color="primary"
      v-bind="attrs"
          v-on="on"
    >
      <v-icon dark>
        mdi-account-circle
      </v-icon>
    </v-btn>

      </template>

      <v-list>
        <v-list-item
          v-for="item in items_user_logued"
          :key="item"
          to="login"
         
        >
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
 
  
 

  </v-row>
  </v-app-bar>
</template>
<script>
import axios from "axios";
import {useDoorStore} from '../store/index'

  export default {
    setup() {
      const store = useDoorStore()      
      return { store}
    },
    data: () => ({
      btns: [      
        ['Large', 'lg'],
    
      ],
      name:"",
      door :{id :0, name:"Acceso"},
      items: [],
      items_user_logued: [ {id:2, name: "Salir"}],
    }),

    mounted() {
    this.action = "Eliminar" 
    this.getAccess()
  
  },
    methods: {
        updateDoor(door)
        {
          console.log(door)       
        this.store.setDoor(door.id ,door.name)
        },
        getAccess()
        {
            axios
        .get("/entrance-index")
        .then((response) => {  
          console.log(response.data)
          this.items = response.data;         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los accesos"
          );
        });
        },
    },
  }
</script>