<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow ">

                  <span style="color: white;"> Modificar Credencial</span>
                </v-col>

              </v-row>
            </v-toolbar>

            <v-card-text>

              <v-row class="mt-4 ml-4 mr-4">
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-card-account-details" :counter="10"
                    v-model="rut" disabled="true" :rules="rutRules" placeholder="12345678-9" label="RUT *" type="text"></v-text-field>
                </v-col>     
                    <v-col cols="12" md="4">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dates" label="Rango de Fecha de Vigencia de la Credencial"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dates" range locale="es-es">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="credencial_state" no-data-text="No hay datos disponibles" :rules="statusRules"
                    :items="credencial_status" item-text="name" item-value="id" prepend-icon="mdi-map" :disabled="itemDisabled"
                    label="Estado de credencial *" persistent-hint>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select  v-model="credencial_type" no-data-text="No hay datos disponibles" :rules="credencialTypeRules"
                    :items="credencial_types" item-text="name" item-value="id" prepend-icon="mdi-account-details"
                    disabled="true" label="Tipo de Credencial *" persistent-hint>
                  </v-select>
                </v-col>              

              </v-row>

            </v-card-text>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/credential" class="mr-2" text>
                  Cancelar
                </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="update()">
                  Aceptar
                </v-btn></v-col>
            </v-row>
          </v-form>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {

      data: {},
      valid: true,
     
      //Datos del Funcionario

      rut: "",
      dates:['',''],
      credencial_state:"",
      credencial_type:"",
      credencial_status: [],
      credencial_types: [],

      rutRules: [
        v => !!v || 'El RUT es requerido',
        v => (v && v.length <= 10) || 'El RUT debe tener menos de 11 caracteres',
      ],     

      statusRules: [
        v => !!v || 'El Estado de la credencial es requerido',
      ],

      credencialTypeRules: [
        v => !!v || 'El tipo de credencial es requerido',
        
      ],    }
  },
  mounted() {
    this.action = "Eliminar"
    this.chargeData()
    this.getCredencialStatus()
    this.getCredencialTypes()
  },


  methods: {

    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };

      axios
        .post("/credential-show", request)
        .then((response) => {
          console.log("Respuesta");
          console.log(response);
          this.rut = response.data.rut;
          this.dates=[];
          this.dates.push(response.data.start_date);         
          this.dates.push(response.data.end_date) ;
          this.credencial_state = response.data.status_id;
          this.credencial_type = response.data.credencial_type_id;
         })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error cargando datos de la credencial");
        });
    },

    getCredencialStatus() {
      axios
        .get("/status-index")
        .then((response) => {
          this.credencial_status = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las funciones"
          );
        });
    },

    getCredencialTypes() {
      axios
        .get("/credential-type-index")
        .then((response) => {
          this.credencial_types = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    


    update() {
        this.valid = false,
        this.data.id = this.$route.params.id
        this.data.rut = this.rut,
        this.data.start_date = this.dates[0],
        this.data.end_date = this.dates[1],
        this.data.status_id = this.credencial_state,
        this.data.credencial_type_id = this.credencial_type,
        
      axios
        .post("/credential-update", this.data)
        .then(() => {
          this.displayNotification(
            "success",
            "Éxito",
            "Credencial modificada"
          );
          setTimeout(() => this.$router.push({ name: "credential" }), 3000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al modificar datos de la credencial"
          );
        });
    },



  },
};
</script>