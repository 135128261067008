<template>
    <div>
        <v-row>
            <vue-snotify></vue-snotify>
            <v-col cols="12" md="12">
                <v-card class="mt-2 mb-4 ">
                    <v-form pt-4 v-model="valid">
                        <v-toolbar color="primary">
                            <v-row align="center">
                                <v-col class="grow ">
                                    <span style="color: white;"> Crear Credencial</span>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                        <v-card-text>

                            <v-row class="ml-4 mt-2">



                                <v-col cols="12" md="5">
                                    <v-select v-model="typeUser" return-object :items="items" item-text="name" item-value="value"
                                        label="Selecione el tipo de persona">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="5"> <v-text-field prepend-icon="mdi-card-account-details"
                                        v-model="rut" :rules="rutRules" label="Ingrese el RUT *"
                                        type="text"></v-text-field>

                                </v-col>
                                <v-col cols="12" md="1"> <v-btn color="primary" :disabled="!valid"
                                        @click="searchUser()">
                                        Buscar
                                    </v-btn> </v-col>
                            </v-row>

                            <v-row class="ml-4 mt-2 mb-2" v-if="show">
                                <v-col cols="12" md="6">
                                    <v-card color="primary" dark>
                                        <div class="d-flex flex-no-wrap justify-space-between">
                                            <div>
                                                <v-card-title class="text-h5">{{ name }}</v-card-title>
                                                <v-card-subtitle>
                                                    <h5>RUT: {{ rut }}</h5>
                                                    <h5>Email: {{ email }}</h5>
                                                    <h5>Teléfono: {{ phone }}</h5>
                                                </v-card-subtitle>


                                            </div>

                                            <v-avatar class="ma-3" size="125" tile>
                                                <v-img ></v-img>
                                            </v-avatar>
                                        </div>
                                    </v-card>


                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                        :return-value.sync="date" transition="scale-transition" offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="dates"
                                                label="Rango de Fecha de Vigencia de la Credencial"
                                                prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="dates" range locale="es-cl" >

                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-divider class="mb-3"></v-divider>
                        <v-row>
                            <v-col cols="12" md="9"></v-col>
                            <v-col cols="12" md="3">
                                <v-btn to="/credential" text>
                                    Cancelar
                                </v-btn>

                                <v-btn color="primary" :disabled="!show" @click="create()">
                                    Aceptar
                                </v-btn></v-col>
                        </v-row>
                    </v-form>
                </v-card>

            </v-col>
        </v-row>

    </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
    mixins: [SnotifyMixin],
    VueSnotify,

    data() {
        return {
            show: false,
            dates: ['', ''],
            name: "",
            rut: "",
            email: "",
            phone: "",
            typeUser: { },
            data: {},
            valid: true,
            items: [
                {
                    name: 'Alumno(a)',
                    value: 1
                },
                {
                    name: 'Funcionario(a)',
                    value: 2
                },
                {
                    name: 'Visitante',
                    value: 3
                },

            ],


            cards: [
                {
                    color: '#1F7087',
                    src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
                    title: 'Supermodel',
                    artist: 'Foster the People',
                },
                {
                    color: '#952175',
                    src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
                    title: 'Halcyon Days',
                    artist: 'Ellie Goulding',
                },
            ],

            rutRules: [
                v => !!v || 'El RUT es requerido',
                v => (v && v.length <= 12) || 'El RUT debe tener hasta 12 caracteres',
            ],

        }
    },



    methods: {

        searchUser() {
            let request = {
                rut: this.rut,
            };

            let route ="";
            if (this.typeUser.value ==1) {
              route="student-show-by-rut";
            }
            else if (this.typeUser.value ==2) {
              route="official-show-by-rut";
            }
            else if (this.typeUser.value ==3) {
              route="visit-show-by-rut";
            }


            axios
                .post("/"+route, request)
                .then((response) => {
                    console.log(response.data)

                    if (response.data.name === undefined) {
                        this.displayNotification(
                            "error",
                            "Error",
                            "No existe un "+ this.typeUser.name +" registrado con este RUT"
                        );
                    }
                    else {
                        this.name = response.data.name + " " +
                            response.data.last_name + " " + response.data.mother_last_name
                        this.email = response.data.email
                        this.phone = response.data.mobile
                        this.show = true

                    }

                    //  setTimeout(() => this.$router.push({ name: "status" }), 3000);
                })
                .catch((err) => {
                    console.log(err, "error");
                    this.displayNotification(
                        "error",
                        "Error",
                        "Error en la búsqueda"
                    );
                });


        },

        create() {
            
            this.data.rut = this.rut;
            this.data.start_date = this.dates[0] < this.dates[1] ? this.dates[0] : this.dates[1];
            this.data.end_date = this.dates[0] > this.dates[1] ? this.dates[0] : this.dates[1];
            this.data.status_id =1;
            this.data.credencial_type_id = this.typeUser.value;
            this.valid = false;
                axios
                    .post("/credential-store", this.data)
                    .then(() => {
                        this.displayNotification(
                            "success",
                            "Éxito",
                            "Credencial registrada"
                        );
                        setTimeout(() => this.$router.push({ name: "credential" }), 3000);
                    })
                    .catch((err) => {
                        console.log(err, "error");
                        this.displayNotification(
                            "error",
                            err,
                            "Error al crear la credencial"
                        );
                    });
        },



    },
};
</script>